import React, { useState } from 'react';
import { auth } from '../../../config/firebase';
import { sendPasswordResetEmail, fetchSignInMethodsForEmail } from 'firebase/auth';
import { SuccessMessage } from './styles.js';
import { Container, Title, Input, Button, ErrorMessage, LinkText } from '../styles.js';
import { setMetaTags } from '../../../metaconfig.js';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {

      // Verifica se o email está registrado
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length === 0) {
        setError('Esse email não está registrado.');
        return;
      }

      // Envia o email de redefinição de senha
      await sendPasswordResetEmail(auth, email);
      setMessage('Um email foi enviado para redefinir sua senha.');
    } catch (error) {
      console.error('Erro ao solicitar redefinição de senha:', error);
      setError('Erro ao solicitar redefinição de senha. Verifique o email digitado.');
    }
  };

  return (
    <Container>

      {
          setMetaTags({
              title: `Redefinir senha - Serviço X`,
              description: "redefinir minha senha",
              index: false,
          })
      }

      <Title>Esqueceu a Senha</Title>
      {message && <SuccessMessage>{message}</SuccessMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handlePasswordReset}>
        <label htmlFor="email">Email</label>
        <Input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit">Redefinir Senha</Button>
      </form>
      <LinkText to="/login">Voltar ao Login</LinkText>
    </Container>
  );
};

export default ForgotPassword;
