import React, { useState } from 'react';
import { formatDate } from '../../../components/format/date.js';
import { Container, Section } from './styles.js';
import { decryptString } from '../../../components/encrypt-and-decrypt/index.js';

const Details = ({ userData }) => {

  return (
    <Container>
        <Section><h3>Sobre: </h3> <div className='biografia'>{decryptString(userData.user_bio, 'user') || <i>Nenhuma biografia adicionada</i>}</div></Section>
        <Section><h3>Conta criada em: </h3> <p>{formatDate(userData.account_created_at)}</p></Section>
    </Container>
  );
};

export default Details;
