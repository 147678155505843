import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, database } from '../../../../config/firebase.js';
import { Textarea } from './styles.js';
import Modal from '../../../../components/modal/index.js';
import { decryptString, encryptString } from '../../../../components/encrypt-and-decrypt/index.js';

const EditField = ({ field, label, currentValue, onClose, onUpdate }) => {
    
    const [value, setValue] = useState(currentValue);
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async () => {
        setIsLoading(true);
        try {
            const userRef = doc(database, 'users', auth.currentUser.uid);
            const updateData = { [field]: encryptString(value, 'user') };

            if (field === 'user_name') {
                updateData.can_edit_name = false;
            }

            await updateDoc(userRef, updateData);
            onUpdate(encryptString(value, 'user'));
            onClose();
            window.location.reload();
        } catch (error) {
            console.error(`Erro ao atualizar ${label.toLowerCase()}:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            title={`Editar ${label}`}
            isOpen={true}
            onClose={onClose}
            onSave={handleSave}
            isLoading={isLoading}
        >
            <Textarea
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={`Digite seu ${label.toLowerCase()}`}
            />
        </Modal>
    );
};

export default EditField;
