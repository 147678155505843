import styled from 'styled-components';

export const PreviewContainer = styled.div`
  max-width: max-content;
  margin: 20px auto 40px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PreviewImage = styled.img`
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
`;

export const UploadButton = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }
`;

export const EditProfileButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background: #000;
  border-radius: 50px;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background: #303030;
    color: #ccc;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

export const CropperContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: hidden;
`;

export const CloseButton = styled.button`
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #ff4c4c;
  }
`;