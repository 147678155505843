import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { database } from '../../config/firebase';
import { Header, MessageContainer, ModalChat, ModalChatContent, ModalChatHeader } from './styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ChatDetails from './chat-details/index.js';
import { FaArrowLeft } from 'react-icons/fa';
import Chats from './chats';
import { formatName } from '../../components/format/user-name';
import { decryptString } from '../../components/encrypt-and-decrypt';
import { formatString } from '../../components/format/string';
import { setMetaTags } from '../../metaconfig.js';

const Chat = ( { user, loadingUser, userData } ) => {

    const { chatId } = useParams();
    const [ chats, setChats ] = useState([]);
    const [ currentChat, setCurrentChat ] = useState(null);
    const [ otherUserDetails, setOtherUserDetails ] = useState(null);

    const navigate = useNavigate();

    const handleSnapshot = useCallback((snapshot) => {
        const sortedChats = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        
        setChats(sortedChats);

        const chat = sortedChats.find(chat => chat.id === chatId);
        setCurrentChat(chat);

    }, [chatId]);

    useEffect(() => {
        if (!loadingUser) {
            if (user?.uid) {
                const refChat = query(
                    collection(database, "chats"),
                    where("users", "array-contains", user.uid),
                    orderBy('date', 'desc')
                );

                const unsubscribe = onSnapshot(refChat, handleSnapshot);
    
                return () => unsubscribe();
            } else {
                navigate('/login')
            }
        }

    }, [user, loadingUser, handleSnapshot]);

    useEffect(() => {
        if (chatId && chatId !== 'tudo') {
            const chat = chats.find(chat => chat.id === chatId);
            setCurrentChat(chat);
        }
    }, [chatId, chats]);

    return (
        <MessageContainer style={{overflowY: "hidden"}}>
    
            {
                setMetaTags({
                    title: `Mensagens - Serviço X`,
                    description: "Mensagens",
                    index: false,
                })
            }

            <Header>
                <h1>Mensagens</h1>
            </Header>

            <div>
                {chats.length === 0 ? (
                    <p>Você ainda não tem mensagens.</p>
                ) : (
                    <Chats user={user} chats={chats}/>
                )}
            </div>
            {(chatId !== 'tudo' && currentChat) &&
                <ModalChat>
                    <ModalChatContent>
                        <ModalChatHeader>
                            {otherUserDetails?.account_uid &&
                                <Header>
                                    { chatId !== 'tudo' ? <Link className='button-back' to="/mensagens/tudo"><FaArrowLeft size={25} /></Link> : <div></div>}
                                    <Link className='perfil-usuario' to={`/perfil/${formatString(formatName(decryptString(otherUserDetails?.user_name, 'user')))}/${otherUserDetails?.account_uid}/sobre`}>
                                        <img src={otherUserDetails?.user_photo_url} alt={`Foto de perfil de ${formatName(decryptString(otherUserDetails.user_name, 'user'))}`} loading="lazy"/>
                                        <h2>{otherUserDetails?.user_name && formatName(decryptString(otherUserDetails?.user_name, 'user'))}</h2>
                                    </Link>
                                </Header>
                            }
                        </ModalChatHeader>
                        <ChatDetails user={user} chat_id={chatId} chat_details={currentChat} userData={userData} otherUserDetails={otherUserDetails} setOtherUserDetails={setOtherUserDetails} />
                    </ModalChatContent>
                </ModalChat>
            }
        </MessageContainer>
    );
};

export default Chat;
