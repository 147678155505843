import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  border-radius: 8px;
  color: #333;
  line-height: 1.6;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 15px;
`;

export const Text = styled.p`
  font-size: 1rem;
  margin-bottom: 15px;
`;

export const Section = styled.section`
  margin-bottom: 40px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
`;

export const TextArea = styled.textarea`
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  height: 100px;
`;

export const FileInput = styled.input`
  display: none;
`;

export const DropArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 300px;
  border: 2px dashed ${props => (props.isDragged ? '#007BFF' : '#CCCCCC')};
  margin: 10px 0;
  text-align: center;
  cursor: pointer;
  background-color: ${props => (props.isDragged ? '#F0F8FF' : 'transparent')};
  transition: background-color 0.3s ease;

  img {
    position: relative;
    border-radius: 5px;
    margin-top: 10px;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const SubmitButton = styled.button`
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;

export const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: #0056b3;
    }
  }
`;

export const SuccessMessage = styled.p`
  font-size: 1.5rem;
  color: green;
  text-align: center;
  margin-bottom: 20px;
`;

export const HomeButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;