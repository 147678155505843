import React from 'react';
import { PreviewImage, ControlButtons, Button } from './styles.js';
import { FaTrashAlt } from "react-icons/fa";

const ImagePreview = ({ imageInfo, onRemoveImage }) => {

    return (
        <div>
            <PreviewImage src={imageInfo.url} alt="Pré-visualização" />
            <ControlButtons>
                <Button type="button" onClick={onRemoveImage}><FaTrashAlt size={15} /> Remover</Button>
            </ControlButtons>
        </div>
    );
    
};

export default ImagePreview;
