import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    @media (max-width: 450px) {
        margin: 0 15px;
    }
`;

export const Title = styled.h1`
    text-align: center;
    margin-bottom: 50px;
`;

export const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

export const Button = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;

    &:hover {
        background-color: #505050;
    }
`;

export const ErrorMessage = styled.p`
    color: red;
    text-align: center;
`;

export const LinkText = styled(Link)`
    display: block;
    margin-top: 1rem;
    text-align: center;
    color: #3498db;
    text-decoration: none;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }
`;

export const Select = styled.select`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
`;
