import React from 'react';

import { Container } from './styles.js';
import Services from './services/index.js';
import BannerHome from './banner/index.js';
import { setMetaTags } from '../../metaconfig.js';
import Logo from '../../components/images/logo.png';

function Home() {

  return (
    <Container>
                    
        {
          setMetaTags({
              title: `Página inicial - Serviço X`,
              description: "No Serviço X, usuários podem oferecer e contratar serviços online ou físicos diretamente, sem intermediação. Conecte-se com prestadores de serviços e clientes para encontrar as melhores ofertas de acordo com suas necessidades. Você tem liberdade total para negociar valores e condições.",
              author: "Serviço X",
              keywords: `Serviços, serviços digitais, serviços físicos, design, programação`,
              url: window.location.href,
              imageSrc: Logo,
              index: true,
          })
        }

        <main>
          <BannerHome />
          <Services/>
        </main>
    </Container>
  )
}

export default Home
