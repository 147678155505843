import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 400px;
  margin: 0 auto;
`;

export const ForgotPasswordTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

export const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`;

export const SuccessMessage = styled.p`
  color: green;
  margin-bottom: 1rem;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 1rem;
`;

export const LinkText = styled(Link)`
  display: block;
  margin-top: 1rem;
  text-align: center;
  color: #3498db;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;
