import React, { useState, useRef } from 'react';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import Compressor from 'compressorjs';
import { storage, database } from '../../../../config/firebase.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import {
    PreviewContainer,
    PreviewImage,
    EditProfileButton,
    ErrorMessage,
    CropperContainer
} from './styles.js';
import Modal from '../../../../components/modal/index.js';
import { FaEdit } from "react-icons/fa";

const UserImage = ({ user, src, alt }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [cropper, setCropper] = useState(null);
    const [imageSrc, setImageSrc] = useState(src);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const imageRef = useRef(null);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handleFileChange = (file) => {
        if (file) {
            new Compressor(file, {
                quality: 0.7,
                success: (compressedFile) => {
                    setImageSrc(URL.createObjectURL(compressedFile));
                    setFile(compressedFile);
                },
                error: (err) => {
                    setError('Erro ao processar a imagem. Tente novamente.');
                    console.error(err);
                },
            });
            openModal();
        }
    };

    const handleCrop = () => {
        if (cropper) {
            const canvas = cropper.getCroppedCanvas({ width: 200, height: 200 });
            canvas.toBlob(async (blob) => {
                setUploading(true);
                setError('');
                try {
                    const storageRef = ref(storage, `profile-images/${user?.account_uid}/profile-picture.jpg`);
                    await uploadBytes(storageRef, blob);
                    const downloadURL = await getDownloadURL(storageRef);
                    const userRef = doc(database, 'users', user?.account_uid);
                    await updateDoc(userRef, { user_photo_url: downloadURL });
                    alert('Imagem de perfil atualizada com sucesso!');
                    setFile(null);
                    closeModal();
                    window.location.reload();
                } catch (err) {
                    console.error('Erro ao fazer upload da imagem:', err);
                    setError('Erro ao fazer upload da imagem. Tente novamente.');
                } finally {
                    setUploading(false);
                }
            });
        }
    };

    React.useEffect(() => {
        if (imageSrc && imageRef.current) {
            const cropperInstance = new Cropper(imageRef.current, {
                aspectRatio: 1,
                viewMode: 1,
                autoCropArea: 1,
                ready() {
                    setCropper(cropperInstance);
                },
            });

            return () => cropperInstance && cropperInstance.destroy();
        }
    }, [imageSrc]);

    const handleEditClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = (e) => handleFileChange(e.target.files[0]);
        fileInput.click();
    };

    return (
        <PreviewContainer>
            <PreviewImage src={src} alt={alt} />
            <EditProfileButton onClick={handleEditClick}><FaEdit size={20} /></EditProfileButton>
            
            <Modal
                title="Alterar foto de Perfil"
                isOpen={modalIsOpen}
                onClose={closeModal}
                onSave={handleCrop}
                isLoading={uploading}
            >
                {imageSrc && (
                    <CropperContainer>
                        <img ref={imageRef} src={imageSrc} alt="Foto de perfil" loading="lazy"/>
                    </CropperContainer>
                )}
                {error && <ErrorMessage>{error}</ErrorMessage>}
            </Modal>
        </PreviewContainer>
    );
};

export default UserImage;
