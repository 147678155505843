import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 1;
`;

export const Dropzone = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    text-align: center;
    border: 2px dashed #aaa;
    padding: 20px;
    margin: 20px 0;
    cursor: pointer;
    font-size: .9rem;
    transition: all .3s ease;

    svg {
        margin-bottom: 20px;
    }

    &:hover {
        color: #007bff;
        border-color: #007bff;
    }

    @media (max-width: 400px) {
        font-size: .8rem;
    }
`;

export const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
`;

export const PreviewImage = styled.img`
    width: 100%;
    height: 90%;
    max-width: 600px;
    max-height: auto;
    object-fit: contain;
`;

export const Button = styled.button`
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 4px;
    cursor: pointer;
    &:disabled {
        background: #ccc;
        cursor: not-allowed;
    }
`;

export const CropperContainer = styled.div`
    width: 100%;
    height: 400px;
    background: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
`;

export const ErrorMessage = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 10px;
`;
