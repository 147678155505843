import CryptoJS from 'crypto-js';

const chatSecretKey = process.env.REACT_APP_CHAT_SECRET_KEY;
const userSecretKey = process.env.REACT_APP_USER_SECRET_KEY;

const getSecretKey = (secretKey) => {
  switch (secretKey) {
    case 'chat':
      return chatSecretKey;
    case 'user':
      return userSecretKey;
    default:
      console.error('Chave secreta inválida fornecida');
      return null;
  }
};

export const encryptString = (string, secretKey) => {
  const key = getSecretKey(secretKey);
  if (!key) {
    return null;
  }
  return CryptoJS.AES.encrypt(string, key).toString();
};

export const decryptString = (string, secretKey) => {
  const key = getSecretKey(secretKey);
  if (!key) {
    return null;
  }
  try {
    const bytes = CryptoJS.AES.decrypt(string, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
};
