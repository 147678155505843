import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { database } from '../../../config/firebase';
import { ServiceDetailContainer, ServiceContent, ServiceImageContainer, ServiceDetailsContainer, ServiceTitle,
    ServiceDescription, ServicePrice, TagContainer, Tag, ErrorMessage, RequestButton, Category, CategoryContainer, Date,
    ContainerLoad,
    ServiceSubTitle
} from './styles.js';
import tagsJSON from '../../../components/services-json/tags.json';
import categoriesJSON from '../../../components/services-json/categories.json';
import statusJSON from '../../../components/services-json/status.json';
import { formatDate } from '../../../components/format/date.js';
import Share from '../../../components/share/index.js';
import Manager from './manager/index.js';
import { formatPrice } from '../../../components/format/price.js';
import UserDetails from './user-details/index.js';
import SendMessage from './send-message/index.js';
import Load from '../../../components/load/index.js';
import { setMetaTags } from '../../../metaconfig.js';
import { formatName } from '../../../components/format/user-name.js';
import { decryptString } from '../../../components/encrypt-and-decrypt/index.js';

const ServiceDetail = ({ user, loadingUser }) => {

    const { id } = useParams();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState(null);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const availableTags = Object.entries(tagsJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    const availableCategories = Object.entries(categoriesJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    const availableStatuses = Object.entries(statusJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    useEffect(() => {
        const fetchService = async () => {
            setLoading(true);
            try {
                const serviceRef = doc(database, 'services', id);
                const docSnap = await getDoc(serviceRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setService(data);
                    if (data.is_visible && data.status === '1') {
                        await getUserData(data.provider_id);
                    } else if (user?.uid === data.provider_id) {
                        await getUserData(data.provider_id);
                    }

                    else {
                        setLoading(false);
                    }
                } else {
                    setError('Serviço não encontrado.');
                    setLoading(false);
                }
            } catch (error) {
                setError('Erro ao carregar detalhes do serviço.');
                console.error(error);
                setLoading(false);
            }
        };

        const getUserData = async (userId) => {
            try {
                const userRef = doc(database, 'users', userId);
                const docSnap = await getDoc(userRef);

                if (docSnap.exists()) {
                    setUserData(docSnap.data());
                }
            } catch (error) {
                setError('Erro ao carregar detalhes do usuário.');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        if (!loadingUser) {
            fetchService();
        }

    }, [id, loadingUser]);

    const getLabelById = (id, list) => {
        const item = list.find(item => item.value === id);
        return item ? item.label : 'Desconhecido';
    };

    if (loading) {
        return (
            <ContainerLoad>
                <Load />
            </ContainerLoad>
        );
    }

    if (error) {
        return (
            <ContainerLoad>
                <ErrorMessage>{error}</ErrorMessage>
                <Link to={'/'}>Voltar</Link>
            </ContainerLoad>
        );
    }

    return (
        <ServiceDetailContainer>
            {((service && ((service.status === "1") && service.is_visible)) || (user?.uid === service?.provider_id)) ? (
                <ServiceContent>
                    
                    {
                        setMetaTags({
                            title: `${service.title} - Serviço X`,
                            description: service.description,
                            author: formatName(decryptString(userData?.user_name, 'user')),
                            keywords: service.tags.map(tagId => ( getLabelById(tagId, availableTags) )),
                            url: window.location.href,
                            imageSrc: service?.image?.url,
                            index: true,
                        })
                    }

                    {user?.uid === service?.provider_id && (
                        <Manager service={service} navigate={navigate} availableStatuses={availableStatuses} getLabelById={getLabelById}/>
                    )}

                    <ServiceTitle>{service.title}</ServiceTitle>
                    <ServiceImageContainer>
                        {service.image && service.image.url && (
                            <img className='service-image' src={service?.image.url} alt={`Imagem do serviço "${service?.title}" do site Serviço X`} loading="lazy" />
                        )}
                    </ServiceImageContainer>
                    <ServiceDetailsContainer>
                        <ServiceSubTitle>Visão geral</ServiceSubTitle>
                        <ServiceDescription>{service.description}</ServiceDescription>
                        {service.price && (
                            <ServicePrice>Preço: {formatPrice(service.price)}</ServicePrice>
                        )}

                        {service.category && (
                            <CategoryContainer>
                                <h3>Categoria:</h3>
                                <Category>{getLabelById(service.category, availableCategories)}</Category>
                            </CategoryContainer>
                        )}

                        {service.tags && (
                            <TagContainer>
                                <h3>Tags:</h3>
                                {service.tags.map(tagId => (
                                    <Tag key={tagId}>{getLabelById(tagId, availableTags)}</Tag>
                                ))}
                            </TagContainer>
                        )}

                        {service.date && (
                            <Date>{formatDate(service.date)}</Date>
                        )}

                        {(user?.uid && service.provider_id !== user?.uid) &&
                            <RequestButton onClick={() => setShowModal(true)}>Solicitar Serviço</RequestButton>
                        }

                        {!user?.uid &&
                            <RequestButton onClick={() => alert("Faça login para solicitar este serviço!")}>Solicitar Serviço</RequestButton>
                        }

                        <Share />

                        <SendMessage showModal={showModal} setShowModal={setShowModal} user={user} service={service} navigate={navigate}/>
                    </ServiceDetailsContainer>
                </ServiceContent>
            ) : (
                <ContainerLoad>
                    <ErrorMessage>Este serviço não está disponível no momento!</ErrorMessage>
                    <Link to={'/'}>Voltar</Link>
                </ContainerLoad>
            )}
            <UserDetails userData={userData}/>
        </ServiceDetailContainer>
    );
};

export default ServiceDetail;
