import React, { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import { 
  Container, 
  Title, 
  SubTitle, 
  Text, 
  Section, 
  Form, 
  Input, 
  TextArea, 
  FileInput, 
  DropArea, 
  SubmitButton, 
  NavigationButtons 
} from './styles.js'; 
import { setMetaTags } from '../../metaconfig.js';
import Logo from '../../components/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { decryptString, encryptString } from '../../components/encrypt-and-decrypt/index.js';

import { database } from '../../config/firebase.js';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

const RequestVerification = ({ user, loadingUser, userData }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [frontFile, setFrontFile] = useState(null); 
  const [backFile, setBackFile] = useState(null); 
  const [frontPreview, setFrontPreview] = useState(null); 
  const [backPreview, setBackPreview] = useState(null);
  const [sending, setSending] = useState(false);
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [alreadyRequested, setAlreadyRequested] = useState(false);

  const frontInputRef = useRef(null);
  const backInputRef = useRef(null);

  const navigate = useNavigate();

  const storage = getStorage();

  useEffect(() => {
    if (!loadingUser) {
      if (!user?.uid) {
        navigate('/')
      }
    }
  }, [user, loadingUser]);

  useEffect(() => {
    if (user?.uid && userData?.account_uid) {
      setUserName(decryptString(userData?.user_name, 'user'));
      setEmail(user?.email);
      checkVerificationStatus();
      setAlreadyVerified(userData?.verified_account || userData?.official_account )
    }
  }, [user, userData]);

  // Verifica se o usuário já é verificado ou já solicitou verificação
  const checkVerificationStatus = async () => {
    const userDocRef = doc(database, `users/${user?.uid}`);
    const verificationRequestRef = doc(database, `request-verification/${user?.uid}`);
    
    try {
      // Verifica se o usuário já é verificado
      const userDoc = await getDoc(userDocRef);

      // Verifica se já existe uma solicitação de verificação pendente
      const verificationRequestDoc = await getDoc(verificationRequestRef);
      if (verificationRequestDoc.exists()) {
        setAlreadyRequested(true);
      }
    } catch (error) {
      console.log('Erro ao verificar status de verificação:', error);
    }
  };

  const handleFileChange = (e, isFront) => {
    const file = e.target.files[0];
    if (isFront) {
      setFrontFile(file);
      setFrontPreview(URL.createObjectURL(file)); 
    } else {
      setBackFile(file);
      setBackPreview(URL.createObjectURL(file)); 
    }
  };

  const sendEmailAndSaveRequest = async () => {
    setSending(true);

    try {
      const frontStorageRef = ref(storage, `/verifications/${user?.uid}/front`);
      const backStorageRef = ref(storage, `/verifications/${user?.uid}/back`);

      const frontSnapshot = await uploadBytes(frontStorageRef, frontFile);
      const frontDownloadURL = await getDownloadURL(frontSnapshot.ref);

      const backSnapshot = await uploadBytes(backStorageRef, backFile);
      const backDownloadURL = await getDownloadURL(backSnapshot.ref);

      const templateParams = {
        user_name: userName,
        user_email: email,
        message: message,
        user_uid: user?.uid,
      };

      await emailjs.send(
        process.env.REACT_APP_EMAIL_SUPPORT_SERVICE_ID,
        process.env.REACT_APP_EMAIL_SUPPORT_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAIL_SUPPORT_USER_ID
      );

      await setDoc(doc(database, `/request-verification/${user?.uid}`), {
        user_uid: user?.uid,
        user_name: encryptString(userName, 'user'),
        user_email: encryptString(email, 'user'),
        message: encryptString(message, 'user') || '',
        front_image_url: encryptString(frontDownloadURL, 'user'),
        back_image_url: encryptString(backDownloadURL, 'user'),
        date: serverTimestamp()
      });

      setCurrentStep(5);
    } catch (error) {
      alert('Erro ao processar a solicitação, tente novamente.');
      console.log('Erro:', error);
    } finally {
      setSending(false);
    }
  };

  // Função para verificar se os campos obrigatórios foram preenchidos
  const canProceedToNextStep = () => {
    if (currentStep === 2) {
      return userName.trim() !== '' && email.trim() !== ''; // Valida nome e email
    } else if (currentStep === 3) {
      return frontFile !== null; // Valida que a frente do RG foi carregada
    } else if (currentStep === 4) {
      return backFile !== null; // Valida que o verso do RG foi carregado
    }
    return true;
  };

  // Função para renderizar o conteúdo da etapa atual
  const renderStepContent = () => {
    if (alreadyVerified) {
      return <Text>Sua conta já está verificada. <Link to={'/'}>Página Inicial</Link></Text>;
    }

    if (alreadyRequested) {
      return <Text>Você já solicitou a verificação de conta. Estamos processando sua solicitação. <Link to={'/'}>Página Inicial</Link></Text>;
    }

    switch (currentStep) {
      case 1:
        return (
          <>
            <Section>
              <Text>
                Contas verificadas possuem diversos benefícios no Serviço X, incluindo maior prioridade para análise de serviços e atendimento mais ágil ao entrar em contato com nossa equipe. Saiba mais em <Link to={'/conta-verificada'}>Contas verificadas</Link>
              </Text>
            </Section>
            <SubmitButton onClick={() => setCurrentStep(2)}>
              Solicitar Verificação
            </SubmitButton>
          </>
        );
      case 2:
        return (
          <Form>
            <h4>Nome</h4>
            <Input 
              type="text" 
              name="user_name" 
              placeholder="Seu nome" 
              value={userName}
              required
              disabled
            />
            <h4>Email</h4>
            <Input 
              type="email" 
              name="user_email" 
              placeholder="Seu e-mail" 
              value={email}
              required
              disabled
            />
            <h4>Mensagem</h4>
            <TextArea
              name="message"
              placeholder="Mensagem (Opcional)"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <NavigationButtons>
              <button 
                type="button" 
                onClick={() => setCurrentStep(3)} 
                disabled={!canProceedToNextStep()}
              >
                Próximo
              </button>
            </NavigationButtons>
          </Form>
        );
      case 3:
        return (
          <>
            <SubTitle>Adicione uma foto da frente do seu RG</SubTitle>
            <DropArea 
              onClick={() => frontInputRef.current.click()}
            >
              {frontPreview ? (
                <img src={frontPreview} alt="Pré-visualização da frente do RG" width="200" />
              ) : (
                'Clique aqui para selecionar a frente do documento.'
              )}
              <FileInput 
                type="file"
                ref={frontInputRef}  
                name="frontFile"
                accept="image/*"
                onChange={(e) => handleFileChange(e, true)}
                required
              />
            </DropArea>
            <NavigationButtons>
              <button type="button" onClick={() => setCurrentStep(2)}>
                Voltar
              </button>
              <button 
                type="button" 
                onClick={() => setCurrentStep(4)} 
                disabled={!canProceedToNextStep()}
              >
                Próximo
              </button>
            </NavigationButtons>
          </>
        );
      case 4:
        return (
          <>
            <SubTitle>Adicione uma foto da parte de trás do seu RG</SubTitle>
            <DropArea 
              onClick={() => backInputRef.current.click()}
            >
              {backPreview ? (
                <img src={backPreview} alt="Pré-visualização do verso do RG" width="200" />
              ) : (
                'Clique aqui para selecionar o verso do documento.'
              )}
              <FileInput 
                type="file"
                ref={backInputRef}  
                name="backFile"
                accept="image/*"
                onChange={(e) => handleFileChange(e, false)}
                required
              />
            </DropArea>
            <NavigationButtons>
              <button type="button" onClick={() => setCurrentStep(3)}>
                Voltar
              </button>
              <button 
                type="button" 
                onClick={sendEmailAndSaveRequest} 
                disabled={!canProceedToNextStep() || sending}
              >
                {sending ? 'Enviando...' : 'Enviar'}
              </button>
            </NavigationButtons>
          </>
        );
      case 5:
        return (
          <>
            <Text>Solicitação enviada com sucesso!</Text>
            <Text>Iremos verificar as informações e retornaremos via email.</Text>
            <Link to={'/'}>Página Inicial</Link>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      {setMetaTags({
        title: `Solicitar verificação - Serviço X`,
        description: "Solicitar selo de verificação do Serviço X.",
        author: "Serviço X",
        keywords: `Sobre Serviço X, Contas Verificadas, Contas Oficiais`,
        url: window.location.href,
        imageSrc: Logo,
        index: true,
      })}
      <Section>
        <Title>Solicitação de Verificação de Conta</Title>
        {renderStepContent()}
      </Section>
    </Container>
  );
};

export default RequestVerification;
