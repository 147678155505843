import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 5px;
`;

export const StatusText = styled.p`
    font-weight: bold;
    margin-right: 5px;
    font-size: .75rem;
    padding: 5px 10px;
    background-color: ${props => {
        switch (props.$status) {
            case '0':
                return 'yellow';
            case '1':
                return 'green';
            case '2':
                return 'red';
            default:
                return 'black';
        }
    }};
    color: ${props => {
        switch (props.$status) {
            case '0':
                return 'black';
            default:
                return 'white';
        }
    }};
    border-radius: 4px;
`;

// Visibility Styles
export const VisibilityText = styled.span`
    font-weight: bold;
    background-color: ${props => (props.$visible ? 'blue' : 'black')}; // Azul claro para Visível, Cinza claro para Oculto
    color: ${props => (props.$visible ? 'white' : 'white')}; // Azul para Visível, Cinza para Oculto
    margin-right: 5px;
    font-size: .75rem;
    padding: 5px 10px;
    border-radius: 4px;
`;

export const EditLink = styled(Link)`
    display: flex;
    margin-right: 5px;
    font-size: .75rem;
    padding: 5px 10px;
    color: #007bff;
    text-decoration: none;
    border: 1px solid #007bff;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: #007bff;
        color: #fff;
    }
`;
export const DeleteButton = styled.button`
    display: inline-block;
    margin-right: 5px;
    font-size: .75rem;
    padding: 5px 10px;
    color: #fff;
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #c82333;
    }

    &:focus {
        outline: none;
    }
`;
