
import React from 'react';
import { Container } from "./styles.js";

const BannerHome = () => {
  return (
    <Container>
        <img
          src={'https://firebasestorage.googleapis.com/v0/b/servico-x.appspot.com/o/banner%2Fbanner.png?alt=media&token=19145dfe-5ea6-4882-b915-2da11712b3c0'}
          alt='Banner de boas vindas do site Serviço X'
          loading="lazy"
        />
    </Container>
  );
};

export default BannerHome;
