import React from 'react';
import { Container, Title, SubTitle, Text, List, ListItem, Strong, Section } from '../styles.js';
import Logo from '../../../components/images/logo.png';
import { setMetaTags } from '../../../metaconfig.js';

const PrivacyPolicy = () => {
  return (
    <Container>
                    
      {
        setMetaTags({
            title: `Política de privacidade - Serviço X`,
            description: "Política de privacidade do site Serviço X.",
            author: "Serviço X",
            keywords: `Política de privacidade, Serviço X`,
            url: window.location.href,
            imageSrc: Logo,
            index: true,
        })
      }

      <Title>Política de Privacidade</Title>
      <Text>Esta política de privacidade descreve como coletamos, usamos e protegemos suas informações pessoais.</Text>

      <Section>
        <SubTitle>1. Coleta de Dados</SubTitle>
        <Text>Coletamos informações fornecidas por você no momento do cadastro, como:</Text>
        <List>
          <ListItem>Nome completo</ListItem>
          <ListItem>Data de nascimento</ListItem>
          <ListItem>Gênero</ListItem>
          <ListItem>Email</ListItem>
        </List>
        <Text>Outras informações que podem ser solicitadas:</Text>
        <List>
          <ListItem>CPF</ListItem>
        </List>
        <Text>Seu CPF será solicitado para verificar a veracidade dos seus dados.</Text>
      </Section>

      <Section>
        <SubTitle>2. Armazenamento de Dados</SubTitle>
        <Text>Todos os dados fornecidos são criptografados e armazenados de forma segura em servidores protegidos, utilizando medidas de segurança adequadas para garantir a confidencialidade e integridade das informações.</Text>
      </Section>

      <Section>
        <SubTitle>3. Uso de Informações</SubTitle>
        <Text>Utilizamos suas informações para oferecer e aprimorar nossos serviços, melhorar sua experiência e garantir a segurança das operações na plataforma. Algumas informações, como seu nome, será exibido para outros usuários a fim de facilitar a comunicação e a negociação de serviços.</Text>
      </Section>

      <Section>
        <SubTitle>4. Compartilhamento de Informações</SubTitle>
        <Text>Não vendemos ou compartilhamos suas informações pessoais com terceiros, exceto caso seja necessário para cumprir com a lei.</Text>
      </Section>

      <Section>
        <SubTitle>5. Segurança</SubTitle>
        <Text>Adotamos medidas de segurança para proteger seus dados pessoais contra acessos não autorizados, uso indevido, divulgação ou alterações. Monitoramos e atualizamos regularmente nossos sistemas para garantir que suas informações estejam seguras.</Text>
      </Section>

      <Section>
        <SubTitle>6. Solicitação de Alteração de Dados</SubTitle>
        <Text>
          Você tem o direito de solicitar a alteração ou atualização de suas informações pessoais. Para garantir a segurança e evitar fraudes ou informações enganosas, pedimos que você envie sua solicitação através do nosso e-mail de contato: 
          <Strong>suporteservicox@gmail.com</Strong>.
        </Text>
        <Text>Após recebermos sua solicitação, nossa equipe realizará uma verificação antes de fazer qualquer alteração em seus dados.</Text>
      </Section>

      <Section>
        <SubTitle>7. Alterações na Política</SubTitle>
        <Text>Podemos atualizar esta política de tempos em tempos. Notificaremos você sobre quaisquer mudanças substanciais por meio de nosso site ou por e-mail. Recomendamos revisar esta página periodicamente.</Text>
      </Section>

      <Section>
        <SubTitle>8. Contato</SubTitle>
        <Text>Se você tiver alguma dúvida, entre em contato pelo email: <Strong>contatoservicox@gmail.com</Strong>.</Text>
      </Section>

      <Section>
        <Text>Atualizado em 01/10/2024.</Text>
      </Section>
    </Container>
  );
};

export default PrivacyPolicy;
