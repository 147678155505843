import styled from 'styled-components';

export const ContainerLoad = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    a {
        padding: 12px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    a:hover {
        background-color: #0056b3;
    }
`

export const ServiceDetailContainer = styled.div`
    display: flex;
    padding: 20px;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;

    @media (max-width: 800px) {
        flex-direction: column;
    }

    @media (max-width: 450px) {
        padding: 15px;
    }

`;

export const ServiceContent = styled.div`
    width: 65%;
    margin-right: 20px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;

    @media (max-width: 800px) {
        width: 100%;
    }

    @media (max-width: 450px) {
        padding: 0;
        border: none;
    }
`;

export const ServiceImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    .service-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 16px;
        border: 1px solid #ddd;
    }
`;

export const ServiceDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ServiceTitle = styled.h1`
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
`;

export const ServiceSubTitle = styled.h2`
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
`;

export const ServiceDescription = styled.div`
    font-size: .95rem;
    color: #666;
    margin-bottom: 20px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
`;

export const ServicePrice = styled.p`
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
`;

export const CategoryContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    h3 {
        font-size: 1rem;
        margin-right: 5px;
    }
`;

export const Category = styled.div`
`;

export const TagContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    overflow-x: auto;

    h3 {
        font-size: 1rem;
        margin-right: 5px;
    }
`;

export const Tag = styled.span`
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-right: 5px;
`;

export const Date = styled.span`
    font-size: 0.9rem;
    margin-bottom: 10px;
`;

export const ErrorMessage = styled.p`
    color: #d9534f;
    font-size: 1rem;
    margin-bottom: 16px;
`;

export const RequestButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;