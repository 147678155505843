import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './layout';
import Home from './pages/home';
import AddService from './pages/services/add';
import ServiceDetail from './pages/services/details';
import EditService from './pages/services/edit';
import Account from './pages/account';
import Profile from './pages/profile';
import Chat from './pages/chat';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import ForgotPassword from './pages/auth/forgot-password';
import PrivacyPolicy from './pages/politicas/privacy';
import TermsService from './pages/politicas/service';
import AboutUs from './pages/about';
import AboutVerifiedAccount from './pages/verified-account';
import RequestVerification from './pages/request-verification';
import Help from './pages/help';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/login" element={<Layout><Login /></Layout>} />
        <Route path="/register" element={<Layout><Register /></Layout>} />
        <Route path="/forgot-password" element={<Layout><ForgotPassword /></Layout>} />
        <Route path="/conta" element={<Layout><Account /></Layout>} />
        <Route path="/mensagens/:chatId" element={<Layout><Chat /></Layout>} />
        <Route path="/perfil/:userName/:id/:label" element={<Layout><Profile /></Layout>} />
        <Route path="/adicionar-servico" element={<Layout><AddService /></Layout>} />
        <Route path="/editar-servico/:id" element={<Layout><EditService /></Layout>} />
        <Route path="/servico/:service-name/:id" element={<Layout><ServiceDetail /></Layout>} />
        <Route path="/politica/privacidade" element={<Layout><PrivacyPolicy /></Layout>} />
        <Route path="/termo/servico" element={<Layout><TermsService /></Layout>} />
        <Route path="/ajuda" element={<Layout><Help /></Layout>} />
        <Route path="/quem-somos" element={<Layout><AboutUs /></Layout>} />
        <Route path="/conta-verificada" element={<Layout><AboutVerifiedAccount /></Layout>} />
        <Route path="/solicitar-verificacao" element={<Layout><RequestVerification /></Layout>} />
      </Routes>
    </Router>
  );
}

export default App;
