
import { useState, useCallback } from 'react';
import { collection, query, orderBy, limit, getDocs, startAfter, where } from 'firebase/firestore';
import { database } from '../../../../config/firebase';

const useRequestServices = (initialServices = []) => {

    const [services, setServices] = useState(initialServices);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasServices, setHasServices] = useState(true);
    const [error, setError] = useState(null);

    const fetchServices = useCallback(async (startAfterDoc = null) => {

        if (loading) return;

        setLoading(true);

        const q = query(
            collection(database, 'services'),
            where('status', '==', "1"),
            where('is_visible', '==', true),
            orderBy('date', 'desc'),
            startAfterDoc ? startAfter(startAfterDoc) : limit(20)
        );

        try {
            const querySnapshot = await getDocs(q);
            const newServices = querySnapshot.docs.map(doc => ({
                ...doc.data()
            }));
            
            setServices(prevServices => startAfterDoc ? [...prevServices, ...newServices] : newServices);
            setHasServices(newServices.length > 0);
            setLastVisible(querySnapshot.docs.length > 0 ? querySnapshot.docs[querySnapshot.docs.length - 1] : null);
            setError(null);
        } catch (err) {
            console.error('Erro ao carregar serviços:', err);
            setError(err.message || 'Erro desconhecido'); // Defina a mensagem de erro como string
        } finally {
            setLoading(false);
        }
    }, [loading]);

    return {
        services,
        fetchServices,
        loading,
        hasServices,
        lastVisible,
        error
    };
};

export default useRequestServices;
