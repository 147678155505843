import React, { useEffect, useState } from 'react';
import { Container, Title, Input, Button, ErrorMessage, LinkText } from '../styles.js'; 
import { auth } from '../../../config/firebase.js';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Load from '../../../components/load/index.js';
import { getErroMessage } from '../messages-erro.js';
import { setMetaTags } from '../../../metaconfig.js';

const Login = ({ user, loadingUser }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  
  useEffect(() => {
    if (!loadingUser && user){
      navigate('/');
    }
  }, [user, loadingUser]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/conta');
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      getErroMessage(error.code, setError);
    }
  };

  if (user?.uid) {
    return;
  }

  return (
    <Container>
      
      {
          setMetaTags({
              title: `Login - Serviço X`,
              description: "Login",
              index: false,
          })
      }

      {loadingUser ?
        <Load/>
        :
        <>
          <Title>Login</Title>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <form onSubmit={handleLogin}>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button type="submit">Login</Button>
          </form>
          <LinkText to={'/forgot-password'}>Esqueceu a senha?</LinkText>
          <LinkText to={'/register'}>Cadastrar-se</LinkText>
        </>
      }
      
    </Container>
  );
};

export default Login;
