import { Container, Header, BioUser, LinkUser, Content, VerifiedOfficialContainer, RatingContainer } from './styles.js';
import { calculateAverageRating } from '../../../../components/calculate-ratings/index.js';
import { IoStar } from "react-icons/io5";
import { Rating } from './styles.js';
import { formatName } from '../../../../components/format/user-name.js';
import { decryptString } from '../../../../components/encrypt-and-decrypt/index.js';
import { formatString } from '../../../../components/format/string.js';
import Verified from '../../../../components/images/check.svg';

function UserDetails( { userData } ) {

    const formatBio = ( bio )=> {
        if (bio.length >= 200) {
            return `${bio.slice(0, 200)}...`
        } else {
            return bio
        }
    }

  return (
    <>
      {userData?.account_uid && (
            <Container>
                <Content>
                    <Header>
                        <img
                            src={userData?.user_photo_url}
                            alt={`Foto de perfil do usuario "${formatName(decryptString(userData?.user_name, 'user'))}" no site Serviço X`}
                            loading="lazy"
                        />
                        <h2>{formatName(decryptString(userData?.user_name, 'user'))}</h2>
                    </Header>
                    <RatingContainer>
                        {(userData?.official_account || userData?.verified_account) &&
                            <VerifiedOfficialContainer to='/conta-verificada'>
                                <img src={Verified} alt='verificado' loading="lazy"/>
                                <p>{userData?.official_account ? "Conta Oficial" : userData?.verified_account ? "Conta Verificada" : ""}</p>
                            </VerifiedOfficialContainer>
                        }   
                        <Rating><IoStar size={15} />{calculateAverageRating(userData?.ratings)} ({userData?.ratings?.length || 0} Avaliações)</Rating>
                    </RatingContainer>
                    <BioUser>{userData?.user_bio != null ? formatBio(decryptString(userData?.user_bio, 'user')) : <i>Ainda não há uma biografia</i>}</BioUser>
                    <LinkUser to={`/perfil/${formatString(formatName(decryptString(userData?.user_name, 'user')))}/${userData?.account_uid}/sobre`}>Ver perfil</LinkUser>
                </Content>
            </Container>
        )}
    </>
  )
}

export default UserDetails
