import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)`

  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  text-decoration: none;
  color: #000;
  transition: all .3s ease;

  &:hover {
    background: #d9d9d9;
  }

  &::before {
    content: '';
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    border-radius: 50px;
    background: ${props => props.$me ? "#f00" : "green"};
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    font-size: .85rem;
  }

  .chat-details {
    display: flex;
    justify-content: space-between;
  }

  .div-image {
    display: flex;
    align-items: center;
  }

  .div-image img {
    margin-right: 10px;
    border-radius: 50%;
  }

  img {
    height: 50px;
    border-radius: 5px;
  }

  @media (max-width: 600px) {

    h2 {
      font-size: .9rem;
      margin-bottom: 0;
    }

    span {
      font-size: .8rem;
    }

    .div-image img {
      margin-right: 5px;
    }

    img {
      height: 35px;
    }
  }

`;