import styled from "styled-components";

export const Container = styled.div `

    display: flex;
    justify-content: center;
    margin: 0 20px;
    height: max-content;

    img {
        max-width: 100%;
        border-radius: 5px;
        aspect-ratio: 16/5;
        object-fit: cover;
    }
    
    @media(max-width: 450px) {
        margin: 0 10px 20px 10px;
    }
`