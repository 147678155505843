import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { database } from '../../../../config/firebase.js';
import {
    ServiceDetailContainer,
    ServiceContent,
    ServiceImageContainer,
    ServiceDetailsContainer,
    ServiceTitle,
    ServiceDescription,
    ServicePrice,
    ErrorMessage,
    ServiceDate,
    Alert,
} from './styles.js';
import { formatPrice } from '../../../../components/format/price.js';
import { formatString } from '../../../../components/format/string.js';
import { formatDate } from '../../../../components/format/date.js';

const ServiceDetail = ({ service_id }) => {

    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {

        const fetchService = async () => {

            setLoading(true);

            try {
                const serviceRef = doc(database, 'services', service_id);
                const docSnap = await getDoc(serviceRef);

                if (docSnap.exists()) {
                    setService(docSnap.data());
                } else {
                    setError('Serviço não encontrado.');
                }
            } catch (error) {
                setError('Erro ao carregar detalhes do serviço.');
                console.error(error);
            }

            finally {
                setLoading(false);
                
            }
        };

        fetchService();

    }, [service_id]);

    if (loading) {
        return <ServiceDetailContainer><span>Carregando...</span></ServiceDetailContainer>;
    }

    if (error) {
        return <ServiceDetailContainer><ErrorMessage>{error}</ErrorMessage></ServiceDetailContainer>;
    }

    return (
        <ServiceDetailContainer>
            {service && <Alert>*Clique para ver mais detalhes*</Alert>}
            {(service) ? (
                <ServiceContent to={`/servico/${formatString(service?.title)}/${service?.service_id}`}>
                    <ServiceImageContainer>
                        <img src={service?.image?.url} alt={`Imagem do serviço ${service?.title}`} loading="lazy"/>
                    </ServiceImageContainer>
                    <ServiceDetailsContainer>
                        <ServiceTitle>{service.title}</ServiceTitle>
                        {service.price && (
                            <ServicePrice>{formatPrice(service.price)}</ServicePrice>
                        )}
                        {service.date && (
                            <ServiceDate>{formatDate(service.date)}</ServiceDate>
                        )}
                    </ServiceDetailsContainer>
                </ServiceContent>
            ) : (
                <ErrorMessage>Este serviço não está disponível</ErrorMessage>
            )}
        </ServiceDetailContainer>
    );
};

export default ServiceDetail;
