import styled from "styled-components";


export const ModalContent = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;

  main section {
    margin-bottom: 20px;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-height: 100px;
    height: 20vh;
    padding: 5px;
  }

  footer {
    display: flex;
    margin-top: 10px;
  }

  footer button:first-child {
    margin-right: 10px;
  }

`;

export const ModalButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;
