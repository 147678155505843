import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.header`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;

  @media (max-width: 600px) {
    padding: 10px;
  }

`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ background }) => (background == 'dark' ? '#fff' : '#333333')};
  background: ${({ background }) => (background == 'dark' ? '#000' : 'unset')};
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;

  img {
    height: 55px;
  }

  &:hover {
    opacity: .7;
  }

  @media (max-width: 600px) {

    img {
      height: 40px;
    }

  }
  
`;

export const Divider = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #dddddd;
  margin: 0 10px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 800px) {
    display: flex;
  }

  div {
    width: 25px;
    height: 3px;
    background-color: #333333;
    margin: 4px 0;
    transition: all 0.3s ease;
  }
`;

export const NavMenu = styled.div`

  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    padding: 5px;
    width: max-content;
    border-radius: 5px;
    z-index: 999;
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ background }) => (background == 'dark' ? '#fff' : '#333333')};
  background: ${({ background }) => (background == 'dark' ? '#000' : 'unset')};
  font-weight: 600;
  font-size: .9rem;
  text-transform: capitalize;
  text-decoration: none;
  padding: 8px;
  margin-right: 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;

  span {
    margin-left: 5px;
  }

  .show-mobile {
    display: none;
  }

  img {
    width: 25px;
    border-radius: 50%;
    border: 1px solid #000;
  }

  &:hover {
    color: #ffffff;
    background-color: #505050;
  }

  @media (max-width: 800px) {
    display: flex;
    padding: 10px;
    margin: 0 0 5px 0;
    width: 100%;

    .show-mobile {
      display: block;
    }
  }
`;

export const ContainerLogin = styled.div `

  display: flex;
  align-items: center;

  a:first-child {
    margin-right: 20px;
  }

  @media (max-width: 600px) {

    a:first-child {
      margin-right: 10px;
    }

  }

`

export const LinkLogin = styled(Link) `

  display: flex;
  align-items: center;
  color: ${({ background }) => (background == 'dark' ? '#fff' : '#333333')};
  background: ${({ background }) => (background == 'dark' ? '#000' : 'unset')};
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
  padding: 8px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;

  &:hover {
    color: #ffffff;
    background-color: #505050;
  }

`