import React from 'react';
import { Container, FooterSection, FooterTitle, FooterItem, FooterBottom } from './styles.js';
import { Link } from 'react-router-dom';
import { formatName } from '../format/user-name.js';
import { formatString } from '../format/string.js';
import { decryptString } from '../encrypt-and-decrypt/index.js';

function Footer({ user, userData, hidden }) {
  return (
    <Container hidden={hidden}>
      <FooterSection>
        <FooterTitle>Sobre Nós</FooterTitle>
        <FooterItem><Link to='/quem-somos'>Quem somos?</Link></FooterItem>
        <FooterItem><Link to='/politica/privacidade'>Política de privacidade</Link></FooterItem>
        <FooterItem><Link to='/termo/servico'>Termos de serviço</Link></FooterItem>
      </FooterSection>
      <FooterSection>
        <FooterTitle>Suporte</FooterTitle>
        <FooterItem><Link to='/ajuda'>Ajuda</Link></FooterItem>
      </FooterSection>
      {userData?.account_uid &&
        <FooterSection>
          <FooterTitle>Conta</FooterTitle>
          <FooterItem><Link to='/conta'>Minha Conta</Link></FooterItem>
          <FooterItem><Link to={`/perfil/${formatString(formatName(decryptString(userData?.user_name, 'user')))}/${userData?.account_uid}/sobre`}>Meu Perfil</Link></FooterItem>
          <FooterItem><Link to={`/perfil/${formatString(formatName(decryptString(userData?.user_name, 'user')))}/${userData?.account_uid}/servicos`}>Meus serviços</Link></FooterItem>
        </FooterSection>
      }
      <FooterBottom>
        <span>Serviço X | 2024. Todos os direitos reservados.</span>
        <span>V 1.1.0 - 27/09/24</span>
      </FooterBottom>
    </Container>
  );
}

export default Footer;
