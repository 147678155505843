import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ProfileContainer = styled.div`
  padding: 20px;
  margin: 0 auto;
  border-radius: 8px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 25px;
  box-shadow: 0 5px 10px #00000099;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const VerifiedOfficialContainer = styled(Link)`

  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px 10px;
  background: ${props => props.background ? props.background : '#000' };
  border-radius: 50px;
  color: #fff;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
      background: #007bff;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  p {
    font-weight: 600;
    font-size: .9rem;
  }
`;

export const ProfileLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const ProfileLink = styled(Link)`
  color: ${({ isactive }) => (isactive === 'true' ? '#ffffff' : '#000')};
  background-color: ${({ isactive }) => (isactive === 'true' ? '#000000' : 'transparent')};
  text-decoration: none;
  margin: 0 10px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${({ isactive }) => (isactive === 'true' ? '#000000' : '#aaa')};
    cursor: ${({ isactive }) => (isactive === 'true' ? 'default' : 'pointer')};
    color: #ffffff;
  }
`;


export const ErrorMessage = styled.div`
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
`;

export const Spinner = styled.div`
  margin: 100px auto;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
