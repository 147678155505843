import { collection, query, orderBy, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { database } from '../../../config/firebase';
import { useEffect, useState, useRef } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import ServiceDetail from './service';
import { MessageContainer, MessageBubble, ContainerMessages, FooterChat } from './styles';
import { LuSendHorizonal } from "react-icons/lu";
import InputMultilineAutosize from './input';
import { decryptString, encryptString } from '../../../components/encrypt-and-decrypt';
import { formatName } from '../../../components/format/user-name';
import { formatDate } from '../../../components/format/date';

const ChatDetails = ({ user, chat_details, chat_id, userData, otherUserDetails, setOtherUserDetails }) => {

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const containerRef = useRef(null);

  // Carrega as mensagens do Firestore e as descriptografa antes de exibir
  const [messageSnapshot] = useCollection(
    query(collection(database, `/chats/${chat_id}/messages`), orderBy('date', 'asc'))
  );

  useEffect(() => {
    if (messageSnapshot) {
      const messageList = messageSnapshot.docs.map(doc => {
        const data = doc.data();
        let decryptedMessage;
        try {
          decryptedMessage = decryptString(data?.message, 'chat'); // Descriptografando a mensagem
        } catch (error) {
          console.error('Erro ao descriptografar a mensagem:', error);
          decryptedMessage = 'Erro ao carregar a mensagem.'; // Tratamento de erro
        }
        return {
          id: doc.id,
          ...data,
          message: decryptedMessage // A mensagem descriptografada é usada no JSX
        };
      });
      setMessages(messageList);

      // Scroll automático para a última mensagem
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 100);
    }

  }, [messageSnapshot]);

  // Carregar detalhes do outro usuário no chat
  useEffect(() => {
    if (chat_details) {
      const fetchUser = async () => {
        setOtherUserDetails(null);
        const user_uid = (chat_details.sender_id === user?.uid ? chat_details.receiver_id : chat_details.sender_id);
        try {
          const userRef = doc(database, 'users', user_uid);
          const userDoc = await getDoc(userRef);
          
          if (userDoc.exists()) {
            setOtherUserDetails(userDoc.data());
          } else {
            console.log('As informações deste usuário não foram encontradas no database.');
          }
        } catch (error) {
          console.error("Ocorreu um erro:", error);
        }
      };

      fetchUser();
    }
  }, [chat_details]);

  // Envia a mensagem criptografada para o Firestore
  const sendMessage = async () => {
    if (message.length > 0) {
      const encryptedMessage = encryptString(message, 'chat'); // Criptografando a mensagem antes de enviar
      await addDoc(collection(database, `chats/${chat_id}/messages`), {
        message: encryptedMessage,
        sender_id: user.uid,
        date: serverTimestamp(),
      });
      setMessage(''); // Limpa o campo de mensagem após o envio
    }
  };

  return (
    <div>
      <ContainerMessages ref={containerRef}>
        <ServiceDetail service_id={chat_details?.service_id} />
        {messages?.map((message, index) => (
          <MessageContainer key={index} $isSender={(message.sender_id === user?.uid)}>
            <MessageBubble $isSender={(message.sender_id === user?.uid)}>
              <div>{message?.message}</div> {/* Exibe a mensagem descriptografada */}
              <span className="date">{formatDate(message?.date, true, true)}</span>
            </MessageBubble>
          </MessageContainer>
        ))}
      </ContainerMessages>
      <FooterChat>
        <InputMultilineAutosize message={message} setMessage={setMessage}/>
        <button onClick={sendMessage}><LuSendHorizonal /></button>
      </FooterChat>
    </div>
  );
};

export default ChatDetails;
