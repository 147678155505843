import React, { useState } from 'react';
import { Container, Logo, NavLink, Nav, HamburgerIcon, NavMenu, ContainerLogin, LinkLogin } from './styles.js';
import { IoAddCircleOutline } from "react-icons/io5";
import { FaBars } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import LogoImage from '../../components/images/logo.png';
import { decryptString } from '../encrypt-and-decrypt/index.js';
import { formatName } from '../format/user-name.js';
import { formatString } from '../format/string.js';
import { CiBoxList } from "react-icons/ci";
import { FaGear } from "react-icons/fa6";

function Header({ user, hidden, userData, loadingUser }) {
  
  const [ isOpen, setIsOpen ] = useState(false);

  if (loadingUser) return;

  return (
    <Container hidden={hidden}>
      <Nav>
        <Logo to='/'><img src={LogoImage} alt={"Logo do site Serviço X"} loading="lazy"/></Logo>
        {(user?.uid && userData?.account_uid) &&
          <>
            <HamburgerIcon onClick={()=>setIsOpen(!isOpen)}><FaBars size={30}/></HamburgerIcon>
            <NavMenu open={isOpen}>
              <NavLink to='/mensagens/tudo' onClick={()=>setIsOpen(false)}>
                <MdOutlineEmail size={24} />
                <span>Mensagens</span>
              </NavLink>
              <NavLink to='/adicionar-servico' onClick={()=>setIsOpen(false)}>
                <IoAddCircleOutline size={24} />
                <span>Postar Serviço</span>
              </NavLink>
              <NavLink to={`/perfil/${formatString(formatName(decryptString(userData?.user_name, 'user')))}/${userData?.account_uid}/servicos`} onClick={()=>setIsOpen(false)}>
                <CiBoxList size={24} />
                <span>Meus Serviços</span>
              </NavLink>
              <NavLink to={`/perfil/${formatString(formatName(decryptString(userData?.user_name, 'user')))}/${userData?.account_uid}/sobre`} onClick={()=>setIsOpen(false)}>
                <img src={userData?.user_photo_url} alt={`Foto de perfil do usuario "${formatName(decryptString(userData?.user_name, 'user'))}" no site Serviço X`}/>
                <span>{decryptString(userData?.user_name, 'user')?.split(' ')[0]}</span>
              </NavLink>
              <NavLink to='/conta' onClick={()=>setIsOpen(false)}>
                <FaGear size={20} />
                <span className='show-mobile'>Configurações</span>
              </NavLink>
              </NavMenu>
            </>
          }
        {!user?.uid &&
          <ContainerLogin>
            <LinkLogin to='/login'>
              <span>Entrar</span>
            </LinkLogin>
            <LinkLogin to='/register' background={'dark'}>
              <span>Criar conta</span>
            </LinkLogin>
          </ContainerLogin>
        }
      </Nav>
    </Container>
  );
}

export default Header;
