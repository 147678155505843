import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './global.css';
import 'react-tippy/dist/tippy.css';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga'
  
ReactGA.initialize("G-0JD9FY4J5P");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HelmetProvider>
);