import { Helmet } from 'react-helmet-async';

export const setMetaTags = ({ title, description, author, url, imageSrc, keywords, index = true }) => {

    var date = new Date();

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="robots" content={index ? "index, follow" : "noindex, nofollow"}/>
            {index &&
                <>
                    <meta name="author" content={author} />
                    <meta name="keywords" content={keywords} />

                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:type" content="website" />
                    {url &&
                        <meta property="og:url" content={url} />
                    }
                    {imageSrc &&
                        <meta property="og:image" content={imageSrc} />
                    }
                </>
            }
        </Helmet>
    );
};