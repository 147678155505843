import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';

export const FormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
`;

export const FormTitle = styled.h2`
  font-size: 1.6rem;
  text-align: center;
  color: #333;
  margin-bottom: 50px;
`;

export const InputBase = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const TextArea = styled(InputBase).attrs({ as: 'textarea' })`
  resize: vertical;
  min-height: 200px;
`;

export const Button = styled.button`
  width: 100%;
  padding: 12px;
  margin: 20px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #0056b3;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;
  display: block;
  font-weight: bold;
`;

export const NumericInput = styled(NumericFormat)`
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

export const Input = styled(InputBase)`
  margin-bottom: 20px;
  border-radius: 5px;
`;

export const SelectInput = styled(Select)`
  margin-bottom: 20px;
  border-radius: 5px;
`;