import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ServiceDetailContainer = styled.div`
    margin: 10px 10px 50px 10px;
`;

export const ServiceContent = styled(Link)`
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #000;
    border-radius: 8px;
    text-decoration: none;
    transition: all .3s ease;

    &:hover {
        background: #007bff;
    }
`;

export const ServiceImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    
    img {
        height: 80px;
        border-radius: 8px;
    }

    @media (max-width: 800px) {
    
        img {
            height: 60px;
        }
        
    }

    @media (max-width: 600px) {
    
        img {
            height: 50px;
        }
        
    }

    @media (max-width: 500px) {
    
        img {
            height: 45px;
        }
        
    }

    @media (max-width: 400px) {
    
        img {
            height: 40px;
        }
        
    }
`;

export const ServiceDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ServiceTitle = styled.h2`
    font-size: 1.2rem;
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const ServicePrice = styled.p`
    font-size: .9rem;
    font-weight: bold;
    color: #ccc;
`;

export const ServiceDate = styled.p`
    font-size: .9rem;
    font-weight: bold;
    color: #ccc;
`;

export const Alert = styled.p `
    font-size: .7rem;
    text-align: center;
    font-weight: bold;
    color: #aaa;
    margin: 20px 0 10px 0;
    width: 100%;
`;

export const ErrorMessage = styled.p`
    color: #d9534f;
    font-size: 1rem;
    margin-bottom: 16px;
`;
