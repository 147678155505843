import styled from 'styled-components';

export const LogoutButton = styled.button`
    padding: 10px 20px;
    margin: 50px auto 30px auto;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #333;
    }

    &:active {
        background-color: #555;
    }

    &:focus {
        outline: none;
    }
`;
