import { useState } from 'react';
import CryptoJS from 'crypto-js';
import { sendMessage } from '../../../chat/send/index.js';
import Modal from '../../../../components/modal/index.js';
import { ModalContent, ModalButton } from './styles.js';
import { serverTimestamp } from 'firebase/firestore';

const secretKey = process.env.REACT_APP_CHAT_SECRET_KEY;

const encryptMessage = (message) => {
  return CryptoJS.AES.encrypt(message, secretKey).toString();
};

const SendMessage = ({ showModal, setShowModal, user, service, navigate }) => {
  const [message, setMessage] = useState('Olá, tenho interesse neste serviço.');
  const [isConfirmed, setIsConfirmed] = useState(false); // Controle de confirmação

  // Função para envio da mensagem
  const handleSendRequest = async () => {
    const encryptedMessage = encryptMessage(message);

    const requestMessage = {
      sender_id: user.uid,
      service_id: service.service_id,
      message: encryptedMessage,
      date: serverTimestamp(),
    };

    sendMessage(service, user.uid, service.provider_id, service.service_id, requestMessage, navigate);
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
  };

  const handleCancel = () => {
    setIsConfirmed(false);
    setShowModal(false)
  };

  return (
    <Modal isOpen={showModal} hiddenFooter={true} title={!isConfirmed ? "Importante!" : "Enviar Mensagem"}>
      <ModalContent>
        {!isConfirmed ? (
          <>
            <main>
              <section>
                <p>Todas as negociações realizadas neste site são de responsabilidade exclusiva das partes envolvidas, ou seja, comprador e vendedor. O Serviço X não se responsabiliza por eventuais problemas ou conflitos que possam surgir durante as transações.</p>
              </section>
              <section>
                <p>Saiba mais em <a href='/termo/servico'>Políticas de uso</a></p>
              </section>
            </main>

            <footer>
              <ModalButton onClick={handleCancel}>Cancelar</ModalButton>
              <ModalButton onClick={handleConfirm}>Confirmar</ModalButton>
            </footer>
          </>
        ) : (
          <>
            <main>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Digite sua mensagem aqui..."
              />
            </main>
            <footer>
              <ModalButton onClick={handleCancel}>Cancelar</ModalButton>
              <ModalButton onClick={handleSendRequest}>Enviar</ModalButton>
            </footer>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SendMessage;
