import styled from "styled-components";

export const MessageContainer = styled.div`

  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;

  @media (max-width: 600px) {
    padding: 0 10px;
  }

`;

export const Header = styled.header `
  
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  div {
    width: 40px;
    height: 40px;
  }

  .button-back {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    margin-right: 20px;
    text-decoration: none;
    background: #d9d9d9;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    transition: all .3s ease;
  }

  .button-back:hover {
    background: #000;
    color: #fff;
  }

  .perfil-usuario {
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
  }

  .perfil-usuario:hover {
    text-decoration: underline;
  }

  .perfil-usuario h2 {
    font-size: 1rem;
  }

  .perfil-usuario img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    border: 1px solid #aaa;
    margin-right: 5px;
  }

  @media (max-width: 600px) {

    padding-top: 0;

    h1, h2 {
      margin: 0 5px;
      font-size: 1.3rem;
    }

    .button-back {
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }

    .perfil-usuario img {
      width: 30px;
      height: 30px;
    }

    svg {
      transform: scale(.75);
    }
    
  }

`

export const ModalChat = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalChatContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 1000px;
  max-width: 90%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const ModalChatHeader = styled.div`
  margin-bottom: 20px;
  text-align: center;

  h2 {
      margin: 0;
  }
`;
