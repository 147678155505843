import { deleteDoc, doc, getDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { database, storage } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';

export const handleDeleteService = async (serviceId, redirect, navigate, setError, services, setServices) => {
    try {
        // Exibe uma caixa de confirmação para o usuário
        const confirmDelete = window.confirm('Tem certeza de que deseja excluir este serviço? Esta ação não pode ser desfeita.');

        // Se o usuário não confirmar, a função é encerrada
        if (!confirmDelete) {
            return;
        }

        const serviceRef = doc(database, 'services', serviceId);
        const docSnap = await getDoc(serviceRef);

        if (docSnap.exists()) {
            const serviceData = docSnap.data();
            const imagePath = serviceData.image?.path;

            if (imagePath) {
                const imageRef = ref(storage, imagePath);
                await deleteObject(imageRef);
            }

            await deleteDoc(serviceRef);

            // Atualiza a lista de serviços no estado
            if (setServices) {
                setServices(services.filter(service => service.id !== serviceId));
            }

            // Redireciona o usuário, se necessário
            if (redirect) {
                navigate('/');
            }
        }
    } catch (error) {
        if (setError) {
            setError('Erro ao excluir serviço.');
        }
        console.error(error);
    }
};
