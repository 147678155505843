import React from 'react';
import {
    Container,
    EditLink,
    DeleteButton,
    StatusText,
    VisibilityText,
} from './styles.js';
import { handleDeleteService } from '../../../../components/delete-service/index.js';

function Manager( { service, navigate, availableStatuses, getLabelById } ) {
  return (
    <Container>
        <EditLink to={`/editar-servico/${service?.service_id}/`}>Editar</EditLink>
        <DeleteButton onClick={() => handleDeleteService(service?.service_id, true, navigate)}>Excluir</DeleteButton>
        <StatusText $status={service?.status}>{getLabelById(service?.status, availableStatuses)}</StatusText>
        <VisibilityText $visible={service?.is_visible}>
            {service?.is_visible ? "Visível" : "Oculto"}
        </VisibilityText>
    </Container>
  )
}

export default Manager
