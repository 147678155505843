import styled from "styled-components";

export const ContainerMessages = styled.div `

  position: relative;
  max-height: 70dvh;
  overflow-y: auto;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-top: -20px;

  @media (max-width: 600px) {
    max-height: 65dvh;
  }

`

export const MessageContainer = styled.div`
  display: flex;
  justify-content: ${props => props.$isSender ? 'flex-end' : 'flex-start'};
  margin: 10px;

  img {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    border: 1px solid #aaa;
  }
`;

export const MessageBubble = styled.div`
  background-color: ${props => props.$isSender ? '#007bff60' : '#E4E6EB'};
  color: #000;
  padding: 15px;
  border-radius: 10px;
  max-width: 60%;
  margin: ${props => props.$isSender ? '0 10px 0 0' : '0 0 0 10px'};
  order: ${props => props.$isSender ? 0 : 1};
  
  div {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    margin-bottom: 10px;
  }

  .date {
    font-size: .8rem;
    opacity: .7;
  }

  @media (max-width: 600px) {
    max-width: 80%;
  }
`;

export const FooterChat = styled.footer`
  margin-top: 10px;
  display: flex;
  align-items: center;
  
  input {
    height: 40px;
    padding: 5px;
    margin-right: 5px;
    border: 2px solid #ccc;
    width: 100%;
    max-height: 60px;
    line-height: 20px;
    overflow-y: hidden;
    white-space: pre-wrap;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    height: 40px;
    border-radius: 5px;
    width: 40px;
    cursor: pointer;
    transition: all .3s ease;
  }

  button:hover {
    background: #202020;
  }
`;