import styled from "styled-components";

export const PopupContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PopupContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    h2 {
        margin-bottom: 20px;
        text-align: center;
    }
`;

export const Textarea = styled.textarea`
    width: 100%;
    max-width: 100%;
    height: 100px;
    max-height: 50vh;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    font-size: 16px;
`;

export const PopupButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PopupButton = styled.button`
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }

    &:first-child {
        background-color: #f44336;

        &:hover {
            background-color: #e53935;
        }
    }
`;