import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div `

    width: 35%;

    @media (max-width: 800px) {
        width: 100%;
        margin-top: 30px;
    }

`

export const Content = styled.div `
    padding: 10px 20px 20px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;

    @media (max-width: 450px) {
        padding: 0;
        padding: 10px 5px 20px 5px;
    }
`

export const Header = styled.header `
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 20px;
    text-decoration: none;
    color: #000;
    font-size: .8rem;
    text-decoration: none;
    font-weight: bold;
    transition: all .3s ease;

    img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        margin-right: 10px;
        object-fit: cover;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
`;

export const RatingContainer = styled.div `
    display: flex;
    margin-bottom: 10px;

    @media (max-width: 1100px) {
        flex-direction: column;
    }

    @media (max-width: 800px) {
        flex-direction: row;
    }

    @media (max-width: 400px) {
        flex-direction: column;
    }
`

export const Rating = styled.span `
    display: flex;
    align-items: center;
    font-size: .9rem;

    svg {
        margin-right: 5px;
    }
`

export const VerifiedOfficialContainer = styled(Link)`

    display: flex;
    align-items: center;
    margin: 0 10px 0 0;
    width: max-content;
    padding: 5px 10px;
    background: #000;
    border-radius: 50px;
    color: #fff;
    text-decoration: none;
    transition: all .3s ease;

    &:hover {
        background: #007bff;
    }

    img {
        width: 13px;
        height: 13px;
        margin-right: 5px;
    }

    p {
        font-weight: 600;
        font-size: .8rem;
    }

    @media (max-width: 1100px) {
        margin: 0 0 15px 0;
    }

    @media (max-width: 800px) {
        margin: 0 10px 0 0;
    }

    @media (max-width: 400px) {
        margin: 0 0 15px 0;
    }
`;

export const BioUser = styled.p `
    margin: 20px 0;
`

export const LinkUser = styled(Link)`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s;
  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
`;