import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom'; // Para navegação
import { 
  Container, 
  Title, 
  Section, 
  Form, 
  Input, 
  TextArea, 
  SubmitButton, 
  NavigationButtons, 
  SuccessMessage, 
  HomeButton 
} from './styles.js'; 
import { setMetaTags } from '../../metaconfig.js';
import Logo from '../../components/images/logo.png';

const Help = ({ user }) => {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.uid) {
      setUserName(user.displayName || '');
      setEmail(user.email || '');
    }
  }, [user]);

  const sendHelpRequest = async () => {
    setSending(true);

    try {
      const templateParams = {
        user_name: userName,
        user_email: email,
        message,
        user_uid: user?.uid,
        logged: user?.uid ? true : false,
      };

      // Envia o e-mail via EmailJS
      await emailjs.send(
        process.env.REACT_APP_EMAIL_CONTACT_SERVICE_ID,
        process.env.REACT_APP_EMAIL_CONTACT_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAIL_CONTACT_USER_ID
      );

      // Exibe mensagem de sucesso ao invés do formulário
      setSuccess(true);
    } catch (error) {
      console.error('Erro ao enviar solicitação de ajuda:', error);
      alert('Erro ao enviar a solicitação. Por favor, tente novamente.');
    } finally {
      setSending(false);
    }
  };

  return (
    <Container>
      {setMetaTags({
        title: `Solicitação de Ajuda - Serviço X`,
        description: "Página para solicitação de ajuda e reportar erros no Serviço X.",
        author: "Serviço X",
        keywords: `Ajuda, Suporte, Reportar Erros, Serviço X`,
        url: window.location.href,
        imageSrc: Logo,
        index: true,
      })}

      {success ? (
        <Section>
          <SuccessMessage>Sua solicitação foi enviada com sucesso!</SuccessMessage>
          <HomeButton onClick={()=>navigate('/')}>Página Inicial</HomeButton>
        </Section>
      ) : (
        <Section>
          <Title>Solicitar Ajuda</Title>
          <Form>
            <h4>Nome</h4>
            <Input 
              type="text" 
              name="user_name" 
              placeholder="Seu nome" 
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required={!user?.uid}
            />
            <h4>Email</h4>
            <Input 
              type="email" 
              name="user_email" 
              placeholder="Seu e-mail" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={!user?.uid}
            />
            <h4>Mensagem</h4>
            <TextArea
              required
              name="message"
              placeholder="Descreva o problema ou solicitação"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <NavigationButtons>
              <SubmitButton 
                type="button" 
                onClick={sendHelpRequest}
              >
                {sending ? 'Enviando...' : 'Enviar Solicitação'}
              </SubmitButton>
            </NavigationButtons>
          </Form>
        </Section>
      )}
    </Container>
  );
};

export default Help;
