import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import { auth, database } from './config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import ReactGA from 'react-ga';

const Layout = ({ children, noHeaderFooterPaths = [] }) => {

  const location = useLocation();
  const showHeaderFooter = !noHeaderFooterPaths.includes(location.pathname);
  
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    ReactGA.pageview(location.pathname + location.search);

  }, [location]);
  
  useEffect(() => {
    
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      
      if (user) {
        setUser(user);
        setLoadingUser(false);
        
        const fetchUser = async () => {
          try {
            const userRef = doc(database, 'users', user.uid);
            const userDoc = await getDoc(userRef);
            
            if (userDoc.exists()) {
              setUserData(userDoc.data());
            }
          } catch (error) {
            console.error("Ocorreu um erro:", error);
          }
        };
    
        fetchUser();
      } else {
        setUser(null);
        setLoadingUser(false);
      }
    });

    return () => unsubscribe();

  }, []);

  return (
    <div>
      <Header user={user} hidden={!showHeaderFooter} userData={userData} />
        <main style={{padding: '100px 0', minHeight: '100vh'}}>
          {React.Children.map(children, child => 
            React.cloneElement(child, { user, loadingUser, userData })
          )}
        </main>
      <Footer user={user} hidden={!showHeaderFooter} userData={userData} />
    </div>
  );
};

export default Layout;
