import React from 'react'
import { Container, Price, UserImage, Image, Title, Footer, Main, Header, Content } from './styles.js';
import { formatPrice } from '../format/price.js';
import { formatString } from '../format/string.js';

function Service( { children, service } ) {

  return (
    
    <Container children={children}>
      <Content to={`/servico/${formatString(service?.title)}/${service?.service_id}`}>
        <Header>
          <Image src={service?.image.url} alt={`Imagem do serviço "${service?.title}" no site Serviço X`}  loading="lazy"/>
          <UserImage src={`https://firebasestorage.googleapis.com/v0/b/servico-x.appspot.com/o/profile-images%2F${service?.provider_id}%2Fprofile-picture.jpg?alt=media`} alt="foto de perfil de um usuario do site Serviço X" onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src="https://firebasestorage.googleapis.com/v0/b/servico-x.appspot.com/o/profile-images%2Fprofile.jpg?alt=media&token=dcb1d263-db86-456a-9a4b-1a163f9e7afd"}} loading="lazy"></UserImage>
        </Header>
        <Main>
          <Price>{formatPrice(service?.price)}</Price>
        </Main>
        <Footer>
          <Title>{service?.title}</Title>
        </Footer>
      </Content>
      {children}
    </Container>

  )

}

export default Service
