import React, { useState, useEffect } from 'react';
import { setDoc, serverTimestamp, collection, addDoc } from 'firebase/firestore';
import { auth, database, storage } from '../../../config/firebase';
import ImageUploader from './image-uploader';
import ImagePreview from './image-preview';
import { FormContainer, FormTitle, Input, TextArea, Button, ErrorMessage, Label, NumericInput, SelectInput } from '../styles.js';
import { useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import tagsJSON from '../../../components/services-json/tags.json';
import categoriesJSON from '../../../components/services-json/categories.json';
import typesJSON from '../../../components/services-json/types.json';
import { formatString } from '../../../components/format/string.js';
import { setMetaTags } from '../../../metaconfig.js';

const AddService = ({ user, loadingUser }) => {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState(null);
    const [category, setCategory] = useState(null);
    const [price, setPrice] = useState('');
    const [tags, setTags] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imageInfo, setImageInfo] = useState({});
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const availableTags = Object.entries(tagsJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    const availableCategories = Object.entries(categoriesJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    const availableTypes = Object.entries(typesJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    useEffect(() => {
        if (!user && !loadingUser) navigate('/login');
    }, [user, loadingUser, navigate]);

    const handleAddService = async (e) => {
        e.preventDefault();

        if (!imageFile || !imageInfo?.url) {
            alert('Adicione uma imagem e tente novamente!');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const currentUser = auth.currentUser;

            if (!currentUser) throw new Error('Usuário não autenticado.');

            const serviceRef = await addDoc(collection(database, 'services'), {
                title,
                description,
                category: category.value,
                price: price || null,
                tags: tags.map(tag => tag.value),
                provider_id: currentUser.uid,
                date: serverTimestamp(),
                status: "0",
                is_visible: true,
                image: null
            });

            await setDoc(serviceRef, { service_id: serviceRef.id }, { merge: true });

            if (imageFile) {
                const storageRef = ref(storage, `service-images/${serviceRef.id}.jpg`);
                await uploadBytes(storageRef, imageFile);
                const imageUrl = await getDownloadURL(storageRef);

                await setDoc(serviceRef, {
                    image: {
                        ...imageInfo,
                        url: imageUrl,
                        path: `service-images/${serviceRef.id}.jpg`
                    }
                }, { merge: true });
            }

            alert('Serviço adicionado com sucesso!');
            resetForm();
            navigate(`/servico/${formatString(title)}/${serviceRef.id}`);
        } catch (error) {
            console.error('Erro ao adicionar serviço:', error);
            setError('Erro ao adicionar serviço. Tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setTitle('');
        setDescription('');
        setCategory(null);
        setPrice('');
        setTags([]);
        setImageFile(null);
        setImageInfo({});
    };

    const handleImageUpload = (file) => {
        setImageFile(file);
        setImageInfo({});
        setShowModal(true);
    };

    const handleSaveChanges = (compressedBlob, compressedImageDetails) => {
        setImageFile(compressedBlob);
        setImageInfo(compressedImageDetails);
    };

    const handleRemoveImage = () => {
        setImageFile(null);
        setImageInfo({});
    };

    const handleTagChange = (selectedTags) => setTags(selectedTags || []);
    const handleCategoryChange = (selectedCategory) => setCategory(selectedCategory);
    const handleTypeChange = (selectedType) => setType(selectedType);
    const handlePriceChange = (values) => setPrice(values.value);

    return (

        <FormContainer>
    
            {
                setMetaTags({
                    title: `Adicionar um serviço - Serviço X`,
                    description: "Adicione um serviço ao site e ofereça-o a outros usuarios",
                    index: false,
                })
            }

            <FormTitle>Adicionar Serviço</FormTitle>
            <form onSubmit={handleAddService}>
                <Label htmlFor="title">Título do Serviço</Label>
                <Input
                    id="title"
                    type="text"
                    maxLength={70}
                    placeholder="Adicione um título"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <Label htmlFor="description">Descrição do Serviço</Label>
                <TextArea
                    id="description"
                    placeholder="Adicione uma descrição ao serviço"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                />
                <Label htmlFor="price">Valor</Label>
                <NumericInput
                    id="price"
                    placeholder="Adicione preço ao serviço"
                    format="R$ #.##0,00"
                    value={price}
                    max={999999}
                    onValueChange={handlePriceChange}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    className="input-price"
                    required
                />
                <Label htmlFor="category">Tipo de serviço</Label>
                <SelectInput
                    id="category"
                    options={availableTypes}
                    value={type}
                    onChange={handleTypeChange}
                    placeholder="Selecione um tipo..."
                    required
                />
                <Label htmlFor="category">Categoria</Label>
                <SelectInput
                    id="category"
                    options={availableCategories}
                    value={category}
                    onChange={handleCategoryChange}
                    placeholder="Selecione uma categoria..."
                    required
                />
                <Label htmlFor="tags">Tags</Label>
                <SelectInput
                    id="tags"
                    isMulti
                    options={availableTags}
                    value={tags}
                    onChange={handleTagChange}
                    placeholder="Selecione as tags..."
                    required
                />
                <Label htmlFor="tags">Imagem</Label>

                {imageInfo?.url && (
                    <ImagePreview
                        imageInfo={imageInfo}
                        onEditImage={() => setShowModal(true)}
                        onRemoveImage={handleRemoveImage}
                    />
                )}

                <ImageUploader
                    imageInfoLGlobal={imageInfo}
                    showModal={showModal}
                    onImageUpload={handleImageUpload}
                    onSaveChanges={handleSaveChanges}
                    onClose={() => setShowModal(false)}
                />

                <Button type="submit" disabled={loading}>
                    {loading ? 'Adicionando...' : 'Adicionar Serviço'}
                </Button>
                {error && <ErrorMessage>{error}</ErrorMessage>}
            </form>
        </FormContainer>

    );
};

export default AddService;
