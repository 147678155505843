import * as React from 'react';
import { Input as BaseInput } from '@mui/base/Input';
import { RootDiv, TextareaElement } from './styles';

const Input = React.forwardRef(function CustomInput(props, ref) {
  return (
    <BaseInput
      slots={{
        root: RootDiv,
        input: 'input',
        textarea: TextareaElement,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default function InputMultilineAutosize( { message, setMessage } ) {
  return (
    <Input
        maxRows={3}
        onChange={(e) => setMessage(e.target.value)}
        multiline
        required
        value={message}
        placeholder="Digite uma mensagem…"
    />
  );
}
