
import React, { useEffect } from 'react';
import useRequestServices from './request-services/index.js';
import { ServiceGridContainer, LoadMoreButton, LoadingContainer, NoServicesMessage, NoService, ServiceContainer } from './styles';
import Load from '../../../components/load/index.js';
import Service from '../../../components/service/index.js';
import { Link } from 'react-router-dom';

const ServiceGrid = () => {
    const {
        services,
        fetchServices,
        loading,
        hasServices,
        lastVisible,
        error
    } = useRequestServices();

    useEffect(() => {
        fetchServices();
    }, []);

    return (
        <>
            {loading &&
                <LoadingContainer>
                    <Load/>
                </LoadingContainer>
            }
            {error && <p>{error}</p>}
            {hasServices &&
                <ServiceContainer>
                    <h2 className='subtitle'>Serviços Disponíveis</h2>
                </ServiceContainer>
            }
            <ServiceGridContainer $hasServices={hasServices}>
                {services.length > 0 ? (
                    services.map((service, index) => (
                        <Service service={service} key={index} />
                    ))
                ) : (
                    hasServices ? null : 
                    <NoService>
                        <NoServicesMessage>Ainda não foram publicados serviços</NoServicesMessage>
                        <Link to='adicionar-servico'>Publicar um Serviço</Link>
                    </NoService>
                )}
                {(lastVisible && !loading && services.length >= 20) && (
                    <LoadMoreButton onClick={() => fetchServices(lastVisible)}>
                        Carregar mais
                    </LoadMoreButton>
                )}
            </ServiceGridContainer>
        </>
    );
};

export default ServiceGrid;
