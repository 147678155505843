import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Compressor from 'compressorjs';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { Container, Modal, ModalContent, Dropzone, PreviewContainer, PreviewImage, Button, CloseButton } from './styles.js';
import { FaRegImage } from 'react-icons/fa';

const ImageUploader = ({ imageInfoLGlobal, onImageUpload, onSaveChanges, showModal, onClose }) => {

    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageInfo, setImageInfo] = useState({});
    const [cropperInstance, setCropperInstance] = useState(null);
    const cropperRef = useRef(null);

    useEffect(() => {
        if (image && cropperRef.current) {
            const instance = new Cropper(cropperRef.current, {
                aspectRatio: 1280 / 720,
                viewMode: 1,
                autoCropArea: 1,
            });
            setCropperInstance(instance);
            return () => instance.destroy();
        }
    }, [image]);

    const handleCompressAndCrop = async () => {
        if (cropperInstance) {
            const croppedCanvas = cropperInstance.getCroppedCanvas({
                width: 1280,
                height: 720,
            });

            croppedCanvas.toBlob((blob) => {
                new Compressor(blob, {
                    quality: 0.7,
                    success: (compressedBlob) => {
                        const compressedImageDetails = {
                            ...imageInfo,
                            url: URL.createObjectURL(compressedBlob)
                        };
                        onSaveChanges(compressedBlob, compressedImageDetails);
                        onClose(); // Fecha o popup após salvar
                    },
                });
            }, 'image/jpeg');
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                const imageDetails = {
                    size: file.size,
                    name: file.name,
                    path: URL.createObjectURL(file),
                    url: URL.createObjectURL(file)
                };

                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.onload = () => {
                        imageDetails.resolution = {
                            width: 720,
                            height: 480
                        };
                        setImage(file);
                        setImagePreview(URL.createObjectURL(file));
                        setImageInfo(imageDetails);
                        onImageUpload(file, imageDetails);
                    };
                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
    });

    return (
        <Container>
            {!imageInfoLGlobal?.url && (
                <Dropzone {...getRootProps()}>
                    <input {...getInputProps()} />
                    <FaRegImage size={100} />
                    <p>Arraste e solte uma imagem aqui, ou clique para selecionar uma</p>
                </Dropzone>
            )}
            {showModal &&
                <Modal>
                    <ModalContent>
                        {imagePreview && (
                            <PreviewContainer>
                                <CloseButton onClick={onClose}>X</CloseButton>
                                <PreviewImage
                                    ref={cropperRef}
                                    src={imagePreview}
                                    alt="Pré-visualização"
                                />
                                <Button type='button' onClick={handleCompressAndCrop}>Salvar Alterações</Button>
                            </PreviewContainer>
                        )}
                    </ModalContent>
                </Modal>
            }
        </Container>
    );
};

export default ImageUploader;
