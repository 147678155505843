import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { database } from '../../config/firebase';
import {
  ProfileContainer,
  ProfileInfo,
  ProfileLinkContainer,
  ProfileLink,
  ErrorMessage,
  Image,
  Spinner,
  VerifiedOfficialContainer
} from './styles';
import Share from '../../components/share';
import Services from './services/index.js';
import Details from './details/index.js';
import { formatName } from '../../components/format/user-name.js';
import { decryptString } from '../../components/encrypt-and-decrypt/index.js';
import { formatString } from '../../components/format/string.js';
import Verified from '../../components/images/check.svg';
import { setMetaTags } from '../../metaconfig.js';

const ProfileContent = ({ userData, label, showServices, id, user, loadingUser }) => (
  <ProfileInfo>
    <Image src={userData.user_photo_url} alt={`Foto de perfil do usuario ${formatName(decryptString(userData.user_name, 'user'))}`} loading="lazy" />
    <h1>{formatName(decryptString(userData.user_name, 'user'))}</h1>
    {(userData?.official_account || userData?.verified_account) ?
      <VerifiedOfficialContainer to='/conta-verificada' background={'#000'}>
        <img src={Verified} alt='verificado' loading="lazy"/>
        <p>{userData?.official_account ? "Conta Oficial" : userData?.verified_account ? "Conta Verificada" : ""}</p>
      </VerifiedOfficialContainer>
  :   
    <VerifiedOfficialContainer to='/solicitar-verificacao' background={'#001fca'}>
      <img src={Verified} alt='verificado' loading="lazy"/>
      <p>Solicitar verificação</p>
    </VerifiedOfficialContainer>
  }
    <ProfileLinkContainer>
      <ProfileLink to={`/perfil/${formatString(formatName(decryptString(userData?.user_name, 'user')))}/${userData?.account_uid}/sobre`} isactive={label === 'sobre' ? 'true' : undefined}>Sobre</ProfileLink>
      <ProfileLink to={`/perfil/${formatString(formatName(decryptString(userData?.user_name, 'user')))}/${userData?.account_uid}/servicos`} isactive={label === 'servicos' ? 'true' : undefined}>Serviços</ProfileLink>
    </ProfileLinkContainer>
    {label === 'sobre' && <Details userData={userData} />}
    {showServices && <Services user_id={id} loadingUser={loadingUser} user={user} />}
    <Share />
  </ProfileInfo>
);

const Profile = ({ user, loadingUser }) => {
  
  const { id, label } = useParams();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [showServices, setShowServices] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userRef = doc(database, 'users', id);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          setError('Usuário não encontrado.');
        }
      } catch (error) {
        setError('Erro ao carregar perfil.');
        console.error(error);
      }
    };

    fetchUser();
  }, [id]);

  useEffect(() => {
    setShowServices(label === 'servicos');
  }, [label]);

  if (error) {
    return (
      <ProfileContainer>
        <ErrorMessage>{error}</ErrorMessage>
      </ProfileContainer>
    );
  }

  return (
    <ProfileContainer>
                    
      {userData?.account_uid && setMetaTags({
            title: `${label === 'sobre' ? "Sobre" : "Serviços de"} ${formatName(decryptString(userData?.user_name, 'user'))} - Serviço X`,
            description: decryptString(userData?.user_bio, 'user') || `${label === 'sobre' ? "Saiba mais sobre" : "Veja os serviços de"} ${formatName(decryptString(userData?.user_name, 'user'))} no Serviço X`,
            author: formatName(decryptString(userData?.user_name, 'user')),
            keywords: `${label} ${formatName(decryptString(userData?.user_name, 'user'))}`,
            url: window.location.href,
            imageSrc: userData?.user_photo_url,
            index: true,
        })
      }

      {userData ? <ProfileContent userData={userData} label={label} showServices={showServices} id={id} user={user} loadingUser={loadingUser} /> : <Spinner />}
    </ProfileContainer>
  );
};

export default Profile;
