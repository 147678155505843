import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Container, Title, Input, Select, Button, ErrorMessage, LinkText } from '../styles.js';
import { auth, createUserWithEmailAndPassword, database } from '../../../config/firebase.js';
import { doc, setDoc, Timestamp, collection, getDocs } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { getErroMessage } from '../messages-erro.js';
import Load from '../../../components/load/index.js';
import { encryptString } from '../../../components/encrypt-and-decrypt/index.js';
import { setMetaTags } from '../../../metaconfig.js';
import { versionPolicy } from '../../politicas/version.js';

const Register = ( { user, loadingUser } ) => {

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('1');
  const [birthdate, setBirthdate] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [ datePrivacyPoliyAccepted, setDatePrivacyPoliyAccepted ] = useState();

  const navigate = useNavigate();
  
  useEffect(() => {
    if (!loadingUser && user){
      navigate('/');
    }
  }, [ user, loadingUser ]);

  const getUserIndex = async () => {
    const querySnapshot = await getDocs(collection(database, 'users'));
    return querySnapshot.size + 1; 
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('As senhas não correspondem.');
      return;
    }

    if (!isChecked) {  // Verifica se a checkbox está marcada
      setError('Você deve concordar com a política de privacidade.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(userCredential.user, {
        displayName: fullName
      });
      const user = userCredential.user;

      const index = await getUserIndex();

      await setDoc(doc(database, 'users', user.uid), {
        user_name: encryptString(fullName, 'user'),
        user_email: encryptString(email, 'user'),
        user_photo_url: "https://firebasestorage.googleapis.com/v0/b/servico-x.appspot.com/o/profile-images%2Fdefault%2Fprofile.jpg?alt=media&token=025d8f19-c728-41dd-9ed3-37da651b48e9",
        user_bio: null,
        user_gender: encryptString(gender, 'user'),
        user_birthdate: encryptString(birthdate, 'user'),
        user_email_verified: false,
        user_identity_verified: false,
        account_uid: user.uid,
        account_created_at: Timestamp.now(),
        account_registration_index: index,
        account_type: 1,
        initial_account_check: false,
        terms_agreement: {
          accepted: true,
          date: datePrivacyPoliyAccepted,
          version: versionPolicy()
        }
      }).then(()=> {
        navigate('/conta');
        window.location.reload();
      });

      setFullName('');
      setEmail('');
      setGender('1');
      setBirthdate('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Erro ao criar conta:', error);
      setError(getErroMessage(error.code));
      alert('Erro ao criar conta. Tente novamente.');
    }
  };

  const definePrivacyAccepted = (e)=> {
    setIsChecked(e.target.checked);
    setDatePrivacyPoliyAccepted(Timestamp.now());
  }

  if (user?.uid) {
    return;
  }

  return (
    <Container>
    
      {
          setMetaTags({
              title: `Cadastrar-se - Serviço X`,
              description: "Criar uma conta",
              index: false,
          })
      }

      {loadingUser ? (
        <Load/>
      ) : (
        <>
          <Title>Cadastrar-se</Title>
          <form onSubmit={handleRegister}>
            <label htmlFor="fullName">Nome Completo</label>
            <Input
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="gender">Gênero</label>
            <Select
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="1">Masculino</option>
              <option value="2">Feminino</option>
              <option value="0">Outro</option>
            </Select>
            <label htmlFor="birthdate">Data de nascimento</label>
            <Input
              type="date"
              id="birthdate"
              value={birthdate}
              onChange={(e) => setBirthdate(e.target.value)}
              required
            />
            <label htmlFor="password">Senha</label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="confirmPassword">Confirmar senha</label>
            <Input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <div>
              <input
                type="checkbox"
                id="privacyPolicy"
                checked={isChecked}
                onChange={(e) => definePrivacyAccepted(e)}
                required
              />
              <label htmlFor="privacyPolicy"> Eu concordo com a <Link to='/politica/privacidade'>política de privacidade</Link>, <Link to='/termo/servico'>termos de serviço</Link> e <Link to='/termo/servico'>política de uso</Link></label>
            </div>

            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Button type="submit">Cadastrar-se</Button>
          </form>
          <LinkText to={'/login'}>Fazer Login</LinkText>
        </>
      )}
    </Container>
  );
};

export default Register;
