
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const formatDate = (timestamp, showHours, hideYear) => {
    if (!timestamp) return 'Data não disponível';
    
    const date = timestamp.toDate();
    const currentYear = new Date().getFullYear();
    const timestampYear = date.getFullYear();
    
    // Define o formato da data
    let dateFormat = 'dd/MM/yyyy';
    
    // Se hideYear for verdadeiro e o ano do timestamp for igual ao ano atual, esconder o ano
    if (hideYear && timestampYear === currentYear) {
        dateFormat = 'dd/MM';
    }
    
    // Adiciona o formato de horas e minutos, se showHours for verdadeiro
    if (showHours) {
        dateFormat += " 'às' HH:mm";
    }
    
    return format(date, dateFormat, { locale: ptBR });
};

export const formatDateEua = (date) => {
    if (!date) return 'Data não disponível';
    return `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0,4)}`;
};