import React from 'react';
import { FaShareAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Button } from './styles';

const Share = () => {
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}`;

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: document.title,
                    url: currentUrl
                });
                console.log('Compartilhamento bem-sucedido!');
            } catch (error) {
                console.error('Erro ao compartilhar:', error);
            }
        } else {
            // Fallback para navegadores que não suportam a API Web Share
            alert(`Compartilhe este link: ${currentUrl}`);
        }
    };

    return (
        <Button onClick={handleShare}><FaShareAlt size={24}/> Compartilhar</Button>
    );
};

export default Share;
