import React from 'react';
import { ModalContainer, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalButton } from './styles.js';

const Modal = ({ title, isOpen, onClose, onSave, children, isLoading, hiddenFooter, hiddenButtonSave, closeTitle }) => {
    if (!isOpen) return null;

    return (
        <ModalContainer>
            <ModalContent>
                <ModalHeader>
                    <h2>{title}</h2>
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
                {!hiddenFooter &&
                    <ModalFooter>
                        <ModalButton onClick={onClose}>{closeTitle ? closeTitle : "Cancelar"}</ModalButton>
                        {!hiddenButtonSave &&
                            <ModalButton onClick={onSave} disabled={isLoading}>
                                {isLoading ? 'Salvando...' : 'Salvar'}
                            </ModalButton>
                        }
                    </ModalFooter>
                }
            </ModalContent>
        </ModalContainer>
    );
};

export default Modal;
