import styled from 'styled-components';

export const ContainerLoad = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    a {
        padding: 12px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    a:hover {
        background-color: #0056b3;
    }
`

export const Visibility = styled.div `
    margin: 50px 0 20px 0;
    padding-top: 30px;
    border-top: 2px solid #ddd;

    section {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    label {
        margin: 0 10px 0 0;
    }

    span {
        font-size: .7rem;
    }
`

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  img {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

    span {
        font-size: .7rem;
        margin-top: 10px;
        color: red;
    }
`;
