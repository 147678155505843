import React, { useState, useEffect } from 'react';
import UserImage from './components/user-image';
import { doc, getDoc } from 'firebase/firestore';
import { database } from '../../config/firebase.js';
import { AccountContainer, AccountTitle, AccountInfo, AccountDetailRow, AccountDetailText, ActionButton, DisabledButton } from './styles.js';
import { HandleLogout } from '../../components/logout/index.js';
import { useNavigate } from 'react-router-dom';
import EditField from './components/edit';
import { formatDate, formatDateEua } from '../../components/format/date.js';
import { MdEdit } from 'react-icons/md';
import { IoLockClosed } from 'react-icons/io5';
import genderJSON from '../../components/user-json/gender.json';
import { decryptString } from '../../components/encrypt-and-decrypt/index.js';
import { formatName } from '../../components/format/user-name.js';
import { setMetaTags } from '../../metaconfig.js';

const Account = ({ user, loadingUser }) => {
    const [userData, setUserData] = useState({});
    const [editField, setEditField] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!loadingUser) {
            if (user?.uid) {
                const fetchUserData = async () => {
                    try {
                        const docRef = doc(database, 'users', user.uid);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            setUserData(docSnap.data());
                        }
                    } catch (error) {
                        console.error('Erro ao buscar dados do usuário:', error);
                    }
                };
                fetchUserData();
            } else {
                navigate('/login');
            }
        }
    }, [user, loadingUser, navigate]);

    const handleEditClose = () => setEditField(null);

    const handleUpdate = (field, value) => {
        setUserData(prevState => ({ ...prevState, [field]: value }));
    };

    const renderAccountDetail = (label, value, field, canEdit) => (
        <AccountDetailRow>
            <AccountDetailText>
                <strong>{label}:</strong> {value || 'Informação indisponível'}
            </AccountDetailText>
            {canEdit ? (
                <ActionButton title={`Editar ${label}`} onClick={() => setEditField(field)}><MdEdit size={14} /></ActionButton>
            ) : (
                <DisabledButton disabled><IoLockClosed size={14} /></DisabledButton>
            )}
        </AccountDetailRow>
    );

    const { user_name, user_bio, user_email, user_gender, user_birthdate, account_created_at, user_photo_url, can_edit_name } = userData;

    const availableGenders = Object.entries(genderJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    const getLabelById = (id, list) => {
        const item = list.find(item => item.value === id);
        return item ? item.label : 'Desconhecido';
    };

    return (
        <AccountContainer>
    
            {
                setMetaTags({
                    title: `Minha Conta - Serviço X`,
                    description: "Minha conta",
                    index: false,
                })
            }

            {userData?.account_uid &&
                <>
                    <AccountTitle>Minha Conta</AccountTitle>
                    <UserImage
                        src={user_photo_url}
                        alt={`Foto de perfil do usuario "${formatName(decryptString(userData?.user_name, 'user'))}"`}
                        user={userData}
                    />
                    <AccountInfo>
                        {renderAccountDetail('Nome', decryptString(user_name, 'user'), 'name', can_edit_name)}
                        {renderAccountDetail('Biografia', decryptString(user_bio, 'user') || 'Nenhuma biografia adicionada', 'bio', true)}
                        {renderAccountDetail('Email', decryptString(user_email, 'user'), null, false)}
                        {renderAccountDetail('Gênero', getLabelById(decryptString(user_gender, 'user'), availableGenders), null, false)}
                        {renderAccountDetail('Data de nascimento', formatDateEua(decryptString(user_birthdate, 'user')), null, false)}
                        {renderAccountDetail('Conta criada em', formatDate(account_created_at), null, false)}
                    </AccountInfo>
                    <HandleLogout />
                </>
            }
            {editField === 'name' && (
                <EditField
                    field="user_name"
                    label="Nome"
                    currentValue={decryptString(user_name, 'user')}
                    onClose={handleEditClose}
                    onUpdate={(newName) => handleUpdate('user_name', newName)}
                />
            )}
            {editField === 'bio' && (
                <EditField
                    field="user_bio"
                    label="Biografia"
                    currentValue={decryptString(user_bio, 'user')}
                    onClose={handleEditClose}
                    onUpdate={(newBio) => handleUpdate('user_bio', newBio)}
                />
            )}
        </AccountContainer>
    );
};

export default Account;