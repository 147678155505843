// src/components/ServiceGrid/styles.js
import styled from 'styled-components';

export const ServiceGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.$hasServices ? 4 : 1}, 1fr);
    gap: 20px;
    padding: 20px;
    
    @media(max-width: 1000px) {
        grid-template-columns: repeat(${props => props.$hasServices ? 3 : 1}, 1fr);
    }
    
    @media(max-width: 600px) {
        grid-template-columns: repeat(${props => props.$hasServices ? 2 : 1}, 1fr);
    }
    
    @media(max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 10px;
    }
`;

export const ServiceContainer = styled.div`

    margin: 30px 20px;

    .subtitle {
        font-size: 2rem;
    }

    @media (max-width: 800px) {

        .subtitle {
            font-size: 1.5rem;
        }
    
    }

    @media (max-width: 400px) {

        .subtitle {
            font-size: 1.2rem;
        }
    
    }

`;

export const LoadMoreButton = styled.button`
    grid-column: 1 / -1;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px auto;
    display: block;
    width: fit-content;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    width: 100%;
    padding: 100px;
    margin: auto;
`;

export const NoService = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    a {
        padding: 12px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        text-decoration: none;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    a:hover {
        background-color: #0056b3;
    }

`

export const NoServicesMessage = styled.p`
    font-size: 1rem;
    color: #666;
    text-align: center;
    padding: 20px;
`;
