import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { database } from '../../../config/firebase';
import Switch from 'react-switch';
import { FormContainer, FormTitle, Input, TextArea, Button, ErrorMessage, Label, NumericInput, SelectInput } from '../styles.js';
import tagsJSON from '../../../components/services-json/tags.json';
import categoriesJSON from '../../../components/services-json/categories.json';
import { formatString } from '../../../components/format/string.js';
import { ContainerLoad, Image, Visibility } from './styles.js';
import Load from '../../../components/load/index.js';
import { setMetaTags } from '../../../metaconfig.js';

const EditService = ({ user, loadingUser }) => {
    const { id } = useParams();
    const [service, setService] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(null);
    const [price, setPrice] = useState('');
    const [tags, setTags] = useState([]);
    const [isVisible, setIsVisible] = useState(true);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingService, setLoadingService] = useState(true);
    const [loadingUpdates, setLoadingUpdates] = useState(false);

    const navigate = useNavigate();

    const availableTags = Object.entries(tagsJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    const availableCategories = Object.entries(categoriesJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));

    useEffect(() => {

        setLoadingService(true);
        setLoading(true);
        const fetchService = async () => {
            try {
                const serviceRef = doc(database, 'services', id);
                const docSnap = await getDoc(serviceRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    
                    if (user?.uid !== data.provider_id) {
                        setError('Você não tem permissão para acessar esta página.');
                        setLoading(false);
                        setLoadingService(false);
                        return;
                    }

                    setService(data);
                    setTitle(data.title);
                    setDescription(data.description);
                    setCategory(data.category ? { value: data.category, label: categoriesJSON[data.category] } : null);
                    setPrice(data.price);
                    setTags(data.tags ? data.tags.map(tag => ({ value: tag, label: tagsJSON[tag] })) : []);
                    setIsVisible(data.is_visible)
                    setLoading(false);
                    setLoadingService(false);
                } else {
                    setError('Serviço não encontrado.');
                    setLoading(false);
                    setLoadingService(false);
                }
            } catch (error) {
                setError('Erro ao carregar dados do serviço.');
                console.error(error);
            }
        };

        if (!loadingUser) {

            if (!user?.uid) {
                setError('Você não tem permissão para acessar esta página.');
                setLoading(false);
                return;
            } else {
                fetchService();
            }
        }

    }, [id, user, loadingUser]);

    const handleEditService = async (e) => {
        e.preventDefault();
    
        setLoadingUpdates(true);
        setError('');
    
        try {
            const serviceRef = doc(database, 'services', id);
    
            // Verifica se as informações principais foram alteradas
            const hasChangedInfo = (
                title !== service.title ||
                description !== service.description ||
                category?.value !== service.category ||
                price !== service.price ||
                JSON.stringify(tags.map(tag => tag.value)) !== JSON.stringify(service.tags)
            );
    
            const updatedData = {
                title,
                description,
                category: category.value,
                price: price || null,
                tags: tags.map(tag => tag.value),
                is_visible: isVisible,
            };
    
            // Se alguma informação foi alterada, adiciona o status '0' no update
            if (hasChangedInfo) {
                updatedData.status = '0';
            }
    
            await updateDoc(serviceRef, updatedData);
    
            alert('Serviço atualizado com sucesso!');
            navigate(`/servico/${formatString(title)}/${id}`);
        } catch (error) {
            console.error('Erro ao atualizar serviço:', error);
            setError('Erro ao atualizar serviço. Tente novamente.');
        } finally {
            setLoadingUpdates(false);
        }
    };

    const handleTagChange = (selectedTags) => setTags(selectedTags || []);
    const handleCategoryChange = (selectedCategory) => setCategory(selectedCategory);
    const handlePriceChange = (values) => setPrice(values.value);

    return (
        <FormContainer>
            
            { title && setMetaTags({
                    title: `Editar "${title?.length > 30 ? `${title.slice(0, 30)}...` : title}" - Serviço X`,
                    description: `Editar o serviço "${title}"`,
                    index: false,
                })
            }

            {loading ? (
                <ContainerLoad>
                    <Load />
                </ContainerLoad>
            ) : error ? (
                <ContainerLoad>
                    <ErrorMessage>{error}</ErrorMessage>
                    <Link to={'/'}>Voltar</Link>
                </ContainerLoad>
            ) : (
                <>
                    <FormTitle>Editar Serviço</FormTitle>
                    {loadingService ? 
                        <ContainerLoad>
                            <Load />
                        </ContainerLoad>
                        :
                        <form onSubmit={handleEditService}>
                            <Label htmlFor="title">Título do Serviço</Label>
                            <Input
                                id="title"
                                type="text"
                                placeholder="Adicione um título"
                                value={title}
                                maxLength={70}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                            <Label htmlFor="description">Descrição do Serviço</Label>
                            <TextArea
                                id="description"
                                placeholder="Adicione uma descrição ao serviço"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            />
                            <Label htmlFor="price">Valor</Label>
                            <NumericInput
                                id="price"
                                placeholder="Adicione preço ao serviço"
                                format="R$ #.##0,00"
                                value={price}
                                onValueChange={handlePriceChange}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$ "
                                className="input-price"
                                required
                            />
                            <Label htmlFor="category">Categoria</Label>
                            <SelectInput
                                id="category"
                                options={availableCategories}
                                value={category}
                                onChange={handleCategoryChange}
                                placeholder="Selecione..."
                            />
                            <Label htmlFor="tags">Tags</Label>
                            <SelectInput
                                id="tags"
                                isMulti
                                options={availableTags}
                                value={tags}
                                onChange={handleTagChange}
                                placeholder="Selecione as tags..."
                            />
                            <Label>Imagem</Label>
                            <Image>
                                <img src={service?.image?.url} alt={`Imagem do serviço "${title}"`} loading="lazy" />
                                <span>*Não é possível alterar a imagem</span>
                            </Image>
                            <Visibility>
                                <section>
                                    <Label>Serviço visível</Label>
                                    <Switch
                                        checked={isVisible}
                                        onChange={(checked) => setIsVisible(checked)}
                                        offColor="#888"
                                        onColor="#007bff"
                                        onHandleColor="#fff"
                                        offHandleColor="#fff"
                                        height={25}
                                        width={50}
                                        handleDiameter={17}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                </section>
                                <span>({isVisible ? 'Todos os usuarios' : "Apenas para você"})</span>
                            </Visibility>
                            <Button type="submit" disabled={loadingUpdates}>
                                {loadingUpdates ? 'Atualizando...' : 'Atualizar Serviço'}
                            </Button>
                        </form>
                    }
                </>
            )}
        </FormContainer>
    );
};

export default EditService;
