import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`

    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    }

`;
export const Content = styled(Link)`
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
`;

export const Header = styled.header `
    position: relative;
`

export const Image = styled.img`
    width: 100%;
    height: auto;
    border-top: 1px solid #ddd;
    border-radius: 5px;
`;


export const UserImage = styled.img`
    position: absolute;
    bottom: -25px;
    right: 10px;
    border-radius: 50%;
    background: #fff;
    padding: 5px;
    width: 55px;
    height: 55px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);

    @media (max-width: 700px) {
        width: 45px;
        height: 45px;
        padding: 3px;
    }

    @media (max-width: 700px) {
        width: 45px;
        height: 45px;
        padding: 3px;
    }
`;

export const Main = styled.main `
    margin: 5px 10px;
`

export const Price = styled.p`
    font-size: .85rem;
    color: #909090;
    font-weight: bold;
`;

export const Footer = styled.footer `
    margin: 10px 10px 20px 10px;
`

export const Title = styled.h2`
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
`;