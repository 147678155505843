import React from 'react';
import { Container, Title, SubTitle, Text, Section } from './styles.js';
import { setMetaTags } from '../../metaconfig.js';

import Logo from '../../components/images/logo.png';

const AboutUs = () => {
  return (
    <Container>
      {
        setMetaTags({
            title: `Sobre nós - Serviço X`,
            description: "Quem somos? saiba tudo sobre o Serviço X.",
            author: "Serviço X",
            keywords: `Sobre Serviço X`,
            url: window.location.href,
            imageSrc: Logo,
            index: true,
        })
      }

      <Title>Quem Somos</Title>
      <Text>
        O site Serviço X foi criado para conectar diferentes usuários que oferecem e procuram por serviços, sejam eles digitais ou físicos. A nossa plataforma facilita o encontro entre prestadores e clientes, promovendo liberdade para negociações diretas entre as partes.
      </Text>

      <Section>
        <SubTitle>Nossa Proposta</SubTitle>
        <Text>
          No Serviço X, os usuários têm total autonomia para definir como os serviços serão oferecidos e as formas de pagamento, sem a intervenção do site. Além disso, tentamos solicitar e verificar o máximo de informações possíveis para garantir uma maior segurança para os usuários, assegurando que todas as informações fornecidas sejam verdadeiras. 
          Usuários que confirmam suas informações, como email, e enviam uma foto de um documento válido com foto para verificarmos suas informações, como nome, data de nascimento e CPF, recebem um selo "conta verificada".
        </Text>
        <Text>Para mais detalhes sobre o selo "Conta Verificada", clique <a href="/conta-verificada">aqui</a>.</Text>
      </Section>

      <Section>
        <SubTitle>Missão</SubTitle>
        <Text>
          Nossa missão é conectar pessoas de maneira simples, eficiente e sem intermediários, permitindo que prestadores e clientes tenham controle total sobre suas negociações.
        </Text>
      </Section>

      <Section>
        <SubTitle>Junte-se a Nós</SubTitle>
        <Text>
          Estamos sempre buscando maneiras de aprimorar nossos serviços. Sinta-se à vontade para se cadastrar ou entrar em contato conosco para sugestões e feedbacks.
        </Text>
      </Section>
      <Section>
        <SubTitle>Dúvidas</SubTitle>
        <Text>
          Em caso de dúvidas, entre em contato conosco via email <strong>contatoservicox@gmail.com</strong>.
        </Text>
      </Section>
    </Container>
  );
};

export default AboutUs;
