import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  border-radius: 8px;
  color: #333;
  line-height: 1.6;
`

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 15px;
`;

export const Text = styled.p`
  font-size: 1rem;
  margin-bottom: 15px;
`;

export const Strong = styled.strong`
  font-weight: bold;
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
`;

export const Section = styled.section`
  margin-bottom: 40px;
`;
