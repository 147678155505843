import styled from 'styled-components';

export const Container = styled.footer`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: space-around;
  align-items: flex-start;
  background-color: #f8f8f8;
  padding: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterSection = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin: 20px 0;
    text-align: center;
  }
`;

export const FooterTitle = styled.h3`
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
`;

export const FooterItem = styled.li`
  margin-bottom: 8px;

  a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FooterBottom = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  font-size: 0.9em;
  color: #666;
  flex-basis: 100%;
  text-align: center;

  span {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
