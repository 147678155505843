import styled from 'styled-components';

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
`;

export const AccountTitle = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

export const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;

  .bio {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
`;

export const AccountDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const AccountDetailText = styled.p`
  font-size: 16px;
  color: #555;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin-right: 10px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

export const DisabledButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: #555;
  border: none;
  cursor: not-allowed;
`;
