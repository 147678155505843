import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000020;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

export const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const ModalHeader = styled.div`
    margin-bottom: 20px;
    text-align: center;

    h2 {
        margin: 0;
    }
`;

export const ModalBody = styled.div`
    margin-bottom: 20px;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ModalButton = styled.button`
    padding: 10px 20px;
    background-color: ${(props) => (props.disabled ? '#ccc' : '#4CAF50')};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    &:hover {
        background-color: ${(props) => (props.disabled ? '#ccc' : '#45a049')};
    }

    &:first-child {
        background-color: #f44336;

        &:hover {
            background-color: #e53935;
        }
    }
`;
