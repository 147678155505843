import { formatDate } from '../../../components/format/date.js';
import { Container } from './styles.js';

const Chats = ( { user, chats } )=> {
    return(
        <>
            {chats.map((chat) => (
                <Container to={`/mensagens/${chat?.id}`} key={chat?.id} $me={user?.uid === chat?.sender_id}>
                    <div className='chat-details'>
                        <div className='div-image'>
                            <img src={`https://firebasestorage.googleapis.com/v0/b/servico-x.appspot.com/o/profile-images%2F${(user?.uid === chat?.sender_id) ? chat?.receiver_id : chat?.sender_id}%2Fprofile-picture.jpg?alt=media`}  loading="lazy"/>
                            <div>
                                <h2>{chat?.service_details?.title}</h2>
                                <span>{formatDate(chat?.date)}</span>
                            </div>
                        </div>
                        <div>
                            <img src={chat?.service_details?.image?.url} alt={`Imagem do serviço ${chat?.service_details?.title}`} loading="lazy"/>
                        </div>
                    </div>
                </Container>
            ))}
        </>
    )
}

export default Chats