
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { database } from '../../../config/firebase';

export const sendMessage = async (service, senderId, receiverId, service_id, message, navigate) => {

  try {
    await addDoc(collection(database, 'chats'), {
        users: [senderId, receiverId],
        sender_id: senderId,
        receiver_id: receiverId,
        service_id,
        service_details: service,
        date: serverTimestamp(),
    }).then(async (chatRef) => {
      await addDoc(collection(database, `chats/${chatRef.id}/messages`), {
        ...message
    }).then(()=>{
      navigate(`/mensagens/${chatRef.id}`)
    });
  });
  } catch (error) {
    console.error('Erro ao enviar mensagem:', error);
  }
};