import styled from "styled-components";

export const Button = styled.button `
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    max-width: max-content;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all .3s ease;

    svg {
        margin-right: 5px;
    }

    &:hover {
        background: #505050;
    }
`