
export const getErroMessage = (errorCode, setError) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'Este e-mail já está em uso. Tente fazer login ou use um e-mail diferente.';
      case 'auth/invalid-email':
        return 'O e-mail fornecido não é válido.';
      case 'auth/weak-password':
        return 'A senha deve ter pelo menos 6 caracteres.';
      case 'auth/missing-email':
        return 'O e-mail é obrigatório.';
      case 'auth/missing-password':
        return 'A senha é obrigatória.';
      case 'auth/network-request-failed':
        return 'Falha na conexão com a rede. Verifique sua conexão e tente novamente.';
      case 'auth/user-not-found':
      case 'auth/invalid-credential':
        setError('Email ou senha incorretos. Tente novamente.');
        break;
      case 'auth/user-disabled':
        setError('Essa conta foi desativada.');
        break;
      default:
        return 'Ocorreu um erro. Tente novamente.';
    }
  };