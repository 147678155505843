import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { LogoutButton } from './styles.js';

export const HandleLogout = () => {

    const navigate = useNavigate();

    const handleLogoutClick = async () => {
        const auth = getAuth();

        signOut(auth).then(() => {
            alert('Você foi desconectado com sucesso.');
            navigate('/');
        }).catch((error) => {
            console.error('Erro ao fazer logout:', error);
            alert('Erro ao fazer logout. Tente novamente.');
        });
    };

    return (
        <LogoutButton onClick={handleLogoutClick}>Sair</LogoutButton>
    );
};
