import React from 'react';
import { Container, Title, SubTitle, Text, Section } from './styles.js';
import { setMetaTags } from '../../metaconfig.js';

import Logo from '../../components/images/logo.png';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <Container>
      {
        setMetaTags({
            title: `Conta Verificada - Serviço X`,
            description: "Detalhes sobre contas verificadas do Serviço X.",
            author: "Serviço X",
            keywords: `Sobre Serviço X, Contas Verificadas, Contas Oficiais`,
            url: window.location.href,
            imageSrc: Logo,
            index: true,
        })
      }

      <Title>O que são Contas Verificadas?</Title>
      <Text>
        No Serviço X, contas verificadas são aquelas que passaram por um processo de verificação
        de identidade, incluindo a confirmação de documentos oficiais como RG ou CPF. 
        Isso garante que as informações fornecidas pelo usuário são autênticas e confiáveis.
      </Text>
      
      <Section>
        <SubTitle>Contas verificadas</SubTitle>
        <Text>
          Contas verificadas são identificadas com um selo especial no perfil do usuário,
          indicando que as suas informações pessoais foram confirmadas pelo Serviço X. 
          O processo de verificação inclui a checagem de documentos oficiais, como RG ou CPF,
          o que aumenta a segurança e a confiança entre os usuários da plataforma.
        </Text>
      </Section>
      
      <Section>
        <SubTitle>Contas oficiais</SubTitle>
        <Text>
          Contas oficiais são aquelas que pertencem ao próprio Serviço X. Essas contas são gerenciadas
          pela equipe da plataforma para fornecer suporte, atualizações, e comunicação oficial
          com os usuários. Essas contas também possuem um selo oficial que as distingue de outros
          perfis de usuários.
        </Text>
      </Section>

      <Section>
        <SubTitle>Como verificar sua conta?</SubTitle>
        <Text>Para verificar sua conta, é essencial fornecer informações verdadeiras e precisas. Você deve enviar uma foto de um documento válido com foto, como RG ou CPF, para comprovar sua identidade. Ao fazer isso, sua conta será analisada e, se aprovada, você receberá o selo de 'Conta Verificada'.</Text>
        <Link to={'/solicitar-verificacao'}>Solicitar verificação</Link>
      </Section>

      <Section>
        <SubTitle>Benefícios de ter uma conta verificada</SubTitle>
        <Text>
          Contas verificadas possuem diversos benefícios no Serviço X. Quando você publica um serviço, sua conta tem prioridade na nossa análise para aprovação do serviço. Além disso, usuários com contas verificadas também têm maior prioridade ao entrarem em contato com nossa equipe de suporte, sendo respondidos de forma mais rápida. Ter uma conta verificada garante maior confiança em suas negociações e facilita o uso da plataforma.
        </Text>
      </Section>

      <Section>
        <SubTitle>Importante</SubTitle>
        <Text>
          Mesmo que uma conta seja verificada, isso não garante que o titular da conta não possa
          realizar atividades fraudulentas ou golpistas. O selo de verificação indica que as informações 
          foram confirmadas, mas ainda existe a possibilidade de que os documentos enviados tenham sido 
          falsificados. Por isso, é essencial que você continue tomando cuidado ao interagir com outros 
          usuários, mesmo aqueles com contas verificadas.
        </Text>
        <Text>
          Caso suspeite de qualquer atividade fraudulenta, você pode nos contatar e denunciar via e-mail 
          em <a href="mailto:contatoservicox@gmail.com">contatoservicox@gmail.com</a>. A segurança da nossa comunidade 
          é prioridade, e todas as denúncias serão investigadas.
        </Text>
      </Section>
    </Container>
  );
};

export default AboutUs;
