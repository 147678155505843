import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin: 50px 0;
    max-width: 1000px;
`;

export const Section = styled.section`

  display: flex;
  flex-direction: column;
  margin: 0 0 50px 0;

  h3 {
    margin-bottom: 5px;
  }

  .biografia {
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

`;

export const CopyButton = styled.button`
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: #333;
  }
  
  &:focus {
    outline: none;
  }
`;
