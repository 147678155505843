import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { database } from '../../../config/firebase';
import { Button, ContainerEditDelete, ContainerServiceRejected, ErrorMessage, NoServicesMessage, ServiceGridContainer, StatusText, VisibilityText} from './styles.js';
import { handleDeleteService } from '../../../components/delete-service/index.js';
import statusJSON from '../../../components/services-json/status.json';
import Service from '../../../components/service/index.js';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import Modal from '../../../components/modal/index.js';

const Services = ({ user, loadingUser, user_id, hidden }) => {
    const [services, setServices] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [serviceModal, setServiceModal] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!loadingUser) {
            if (user_id) {
                fetchServices(user_id);
            }
        }
    }, [user_id, loadingUser]);

    const fetchServices = async (uid) => {
        try {
            let servicesQuery;
            if (user?.uid === uid) {
                servicesQuery = query(collection(database, 'services'), where('provider_id', '==', uid));
            } else {
                // Outros usuários, busque apenas serviços visíveis e ativos
                servicesQuery = query(
                    collection(database, 'services'),
                    where('provider_id', '==', uid),
                    where('status', '==', '1'),
                    where('is_visible', '==', true)
                );
            }

            const querySnapshot = await getDocs(servicesQuery);
            const servicesList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setServices(servicesList);
        } catch (error) {
            setError('Erro ao carregar serviços.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (serviceId) => {
        navigate(`/editar-servico/${serviceId}`);
    };

    if (loading) {
        return null;
    };

    const availableStatuses = Object.entries(statusJSON).map(([key, value]) => ({
        value: key,
        label: value
    }));
    
    const getLabelById = (id, list) => {
        const item = list.find(item => item.value === id);
        return item ? item.label : 'Desconhecido';
    };

    const closeModal = ()=> {
        setModalIsOpen(false);
        setServiceModal(null);
    }

    const openModal = (service)=> {
        setModalIsOpen(true)
        setServiceModal(service);
    }

    return (
        <div style={{ display: hidden ? 'none' : 'block' }}>
            {services.length > 0 ? (
                <ServiceGridContainer>
                    {services.length > 0 ? (
                        services.map((service, index) => (
                            <Service service={service} key={index} >
                                {user?.uid === service?.provider_id && (
                                    <>
                                        <ContainerEditDelete>
                                            <Button title={`Editar serviço "${service?.title}"`} onClick={() => handleEdit(service.id)}><MdEdit size={18} /> Editar</Button>
                                            <Button title={`Excluir serviço "${service?.title}"`} onClick={() => handleDeleteService(service.id, false, navigate, setError, services, setServices)}><MdDeleteForever size={18} /></Button>
                                            <StatusText $status={service.status}>{getLabelById(service.status, availableStatuses)}</StatusText>
                                            <VisibilityText $visible={service.is_visible}>{service.is_visible ? "Visível" : "Oculto"}</VisibilityText>
                                        </ContainerEditDelete>
                                        {(service.status === '2') &&
                                            <ContainerEditDelete>
                                                <Button title={`Motivos da rejeição`} onClick={() => openModal(service)}><FaEye size={18} /> Motivos da rejeição</Button>
                                            </ContainerEditDelete>
                                        }
                                    </>
                                )}
                            </Service>
                        ))
                    )
                    : 
                        <NoServicesMessage>Ainda não foram publicados serviços</NoServicesMessage>
                    }
                </ServiceGridContainer>
            ) : (
                <ErrorMessage>Ainda não há nenhum serviço</ErrorMessage>
            )}
            {error &&
                <ErrorMessage>{error}</ErrorMessage>
            }

            <Modal
                title={`Serviço Rejeitado`}
                isOpen={modalIsOpen}
                onClose={closeModal}
                hiddenButtonSave={true}
                closeTitle='Fechar'
            >
                <ContainerServiceRejected>
                    <header>
                        <h3>O serviço "{serviceModal?.title}" foi rejeitado.</h3>
                        <span>Veja a seguir os motivos:</span>
                    </header>
                    <main>
                        <div>{serviceModal?.rejected_message}</div>
                    </main>
                    <footer>
                        <p>*Corrija as informações necessárias e atualize seu serviço para ele passar por uma nova análise</p>
                        <Link to={`/editar-servico/${serviceModal?.service_id}`}>Atualizar Serviço</Link>
                    </footer>
                </ContainerServiceRejected>
            </Modal>
        </div>
    );
};

export default Services;
