import styled from 'styled-components';

export const ServiceList = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
`;

export const ServiceGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    
    @media(max-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
    }
    
    @media(max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media(max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 10px;
    }
`;

export const NoServicesMessage = styled.p`
    grid-column: 1 / -1;
    font-size: 1rem;
    color: #666;
    text-align: center;
    padding: 20px;
`;

export const ContainerEditDelete = styled.div `
    display: flex;
    padding: 0 10px 10px 10px;
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    padding: 8px 10px;
    background-color: #000;
    color: white;
    border: none;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #505050;
    }

    &:first-child svg {
        margin-right: 5px;
    }
`;

export const ErrorMessage = styled.p`
    color: red;
    font-size: 1rem;
    text-align: center;
    margin: 50px 10px;
`;

export const LoadingMessage = styled.p`
    font-size: 1.2rem;
    color: #007BFF;
    text-align: center;
`;

export const StatusText = styled.p`

    position: absolute;
    top: 5px;
    left: 5px;
    font-size: .8rem;
    font-weight: bold;
    border: 1px solid #000;
    background-color: ${props => {
        switch (props.$status) {
            case '0':
                return 'yellow';
            case '1':
                return 'green';
            case '2':
                return 'red';
            default:
                return 'black';
        }
    }};
    color: ${props => {
        switch (props.$status) {
            case '0':
                return 'black';
            default:
                return 'white';
        }
    }};
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
`;

export const VisibilityText = styled.p`
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: .8rem;
    font-weight: bold;
    background-color: ${props => (props.$visible ? 'blue' : 'black')};
    color: ${props => (props.$visible ? 'white' : 'white')};
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
`;

export const ContainerServiceRejected = styled.div`
    
    max-height: 65dvh;
    overflow-y: auto;

    header {
    }

    header h3 {
        margin-bottom: 10px;
    }

    header span {
        font-size: .85rem;
    }

    main {
        margin: 20px 0;
    }

    footer p {
        font-size: .85rem;
        margin-bottom: 20px;
    }

    footer a {
        margin: 0 auto;
        background: #000;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        border-radius: 5px;
    }

`;