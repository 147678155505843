import React from 'react';
import { Container, Section, Title, SubTitle, Text, List, ListItem } from '../styles.js';
import Logo from '../../../components/images/logo.png';
import { setMetaTags } from '../../../metaconfig.js';

const TermsService = () => {
  return (
    <Container>
                    
      {
        setMetaTags({
            title: `Termos de serviço - Serviço X`,
            description: "Termos de serviço do site Serviço X.",
            author: "Serviço X",
            keywords: `Termos de serviço`,
            url: window.location.href,
            imageSrc: Logo,
            index: true,
        })
      }

      <Section>
        <Title>Termos de Serviço</Title>
        <Text>
          Ao utilizar nosso site, você concorda com os seguintes termos e condições. Estes termos regem a relação entre o site e os usuários que oferecem e contratam serviços. Por favor, leia com atenção.
        </Text>

        <SubTitle>1. Definição do Serviço</SubTitle>
        <Text>
          Nosso site conecta prestadores de serviços e clientes para facilitar a contratação de serviços. Não somos responsáveis pelo gerenciamento de pagamentos ou pelo sucesso dos serviços prestados. As negociações são de responsabilidade das partes envolvidas.
        </Text>

        <SubTitle>2. Responsabilidades do Usuário</SubTitle>
        <Text>
          Os usuários são responsáveis por fornecer informações verídicas e manter seu perfil atualizado. O uso inadequado do site, como fornecer informações falsas ou enganosas, pode resultar em suspensão permanente da conta. Além disso, é responsabilidade do usuário proteger suas credenciais de login e não compartilhar sua senha com terceiros.
        </Text>

        <SubTitle>3. Rescisão de Conta</SubTitle>
        <Text>
          Reservamo-nos o direito de suspender ou encerrar contas de usuários que violem nossos termos de serviço ou política de uso. Notificações serão enviadas em caso de violação, com o direito de contestação pelo usuário antes de uma decisão final.
        </Text>

        <SubTitle>4. Modificações nos Termos</SubTitle>
        <Text>
          Podemos modificar os Termos de Serviço de tempos em tempos. Avisaremos os usuários sobre alterações por e-mail ou notificações na plataforma, e recomendamos revisar periodicamente esta página para se manter atualizado.
        </Text>
      </Section>

      <Section>
        <Title>Política de Uso</Title>
        <Text>
          Esta seção descreve as diretrizes para o uso adequado do site. Ao utilizar nossos serviços, você concorda em seguir estas regras para garantir uma experiência positiva e segura para todos os usuários.
        </Text>

        <SubTitle>1. Regras de Uso</SubTitle>
        <List>
          <ListItem>Não é permitido utilizar o site para fins ilegais ou enganosos.</ListItem>
          <ListItem>Os usuários devem respeitar as diretrizes de moderação de conteúdo.</ListItem>
          <ListItem>Não é permitido publicar conteúdo ofensivo ou discriminatório.</ListItem>
          <ListItem>É proibido compartilhar informações pessoais de terceiros sem o consentimento adequado.</ListItem>
        </List>

        <SubTitle>2. Política de Moderação de Conteúdo</SubTitle>
        <Text>
          Temos o direito de moderar e remover conteúdo que viole nossas diretrizes. Isso inclui, mas não se limita a: discurso de ódio, spam, fraudes ou conteúdos que possam causar danos a outros usuários.
        </Text>

        <SubTitle>3. Sanções por Mau Uso</SubTitle>
        <Text>
          Qualquer violação da Política de Uso resultará em sanções que podem variar de advertências até a suspensão permanente da conta, dependendo da gravidade da infração. O usuário será notificado e poderá contestar qualquer sanção imposta antes que uma decisão final seja tomada.
        </Text>
      </Section>

      <Section>
        <SubTitle>Contato</SubTitle>
        <Text>
          Se você tiver alguma dúvida ou precisar de suporte em relação aos Termos de Serviço ou Política de Uso, entre em contato conosco através do email: <strong>contatoservicox@gmail.com</strong>.
        </Text>
      </Section>

      <Section>
        <Text>Atualizado em 01/10/2024.</Text>
      </Section>

      
    </Container>
  );
};

export default TermsService;
